import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { ConfigService } from './config.service';
import { EqcallapiService, PushRegistration } from './eqcallapi.service';
import { SystemBusService, MessageObserver } from './system-bus.service';
import { ContactsService } from './contacts.service';
import { Message } from './message.service';
declare var PushNotification: any;

@Injectable()
export class PushService implements MessageObserver {
    private promptEvent: any;
    public installable = false;
    private endpoint: string;
    private deviceID: string;
    private gotContacts = false
    private subscrided = false;
    private polling = false;
    mobilePush: any;

    constructor(private appConfig: ConfigService, private swPush: SwPush, private swUpdate: SwUpdate,
        private api: EqcallapiService, public systemBus: SystemBusService, private contactsSvc: ContactsService) {
        systemBus.subscribe(this);
    }

    private pollForUpdates() {
        if (this.polling) {
            return;
        }
        this.polling = true;
        this.swUpdate.available.subscribe(() => {
            this.systemBus.emit(this, 'pushService/NewVersion');
        });
        if (this.swUpdate.isEnabled) {
            // Required to enable updates on Windows and ios.
            this.swUpdate.activateUpdate();
            console.log('PushService: NGSW update starting');
            setInterval(() => {
                this.swUpdate.checkForUpdate().then(() => {
                    console.log('checking for updates');
                });
            }, 300000);

        } else {
            console.warn('PushService: pollForUpdates: disabled');
        }
    }

    public subscribe() {
        this.subscribeToNotifications();

    }
    private subscribeToNotifications() {
        if (!this.deviceID) {
            this.deviceID = this.appConfig.getItem('deviceID');
            if (!this.deviceID) {
                this.deviceID = this.uuid();
                this.appConfig.setItem('deviceID', this.deviceID);
            }
        }
        if (navigator.serviceWorker.controller) {
            console.log('Got Service Worker');
            try {
                this.swPush.requestSubscription({
                    serverPublicKey: this.appConfig.vapidKey
                }).then(sub => {
                    this.endpoint = sub.endpoint;

                    let ps = new PushRegistration(this.deviceID);
                    ps.subscription = sub;

                    console.warn('sub=', ps);
                    this.api.setPushSubscription(ps);
                    this.subscrided = true;
                }).catch(err => console.warn('Could not subscribe to notifications', err));
                this.swPush.messages.subscribe({ next: msg => this.processMessage(msg), error: err => console.error(err) });
                this.swPush.notificationClicks.subscribe(notpayload => {
                    this.systemBus.emit(notpayload, 'pushMessageClick');
                });
                if (this.gotContacts || this.contactsSvc.contactsList.numberAll() > 0) {
                    this.checkMessages();
                }
                this.pollForUpdates();
            } catch (err) {
                console.warn('Push Notification subscribe error', err);
            }
        } else {
            if ('PushNotification' in window) {
                console.log('***********Cordova push!************');
                this.mobilePush = PushNotification.init({
                    android: {},
                    browser: {},
                    ios: {
                        alert: 'true',
                        badge: true,
                        sound: 'false'
                    },
                    windows: {}
                });
                this.mobilePush.on('registration', (data: any) => {
                    console.log('Mobile push Registration', data);
                    let ps = new PushRegistration(this.deviceID);
                    ps.registrationId = data.registrationId;
                    ps.registrationType = data.registrationType

                    console.warn('sub=', ps);
                    this.api.setPushSubscription(ps);
                    this.subscrided = true;
                });
                this.mobilePush.on('notification', (data: any) => {
                    this.processMobileMessage(data);
                });
            } else {
                console.log('subscribeToNotifications: Not in Cordova?')
            }
        }
        window.addEventListener('beforeinstallprompt', (event: any) => {
            this.promptEvent = event;
            this.installable = true;
            event.userChoice.then((outcome: string) => {
                console.warn(outcome); // either "accepted" or "dismissed"
            }).catch((err: any) => { console.error(err) });
        });
    }

    private processMessage(msg: any) {
        console.log('PussService: processMessage : message', msg);
        let contactID = msg.data.Address;
        if (contactID) {
            let message = msg.body;
            let contact = this.contactsSvc.getContactByAddress(contactID);
            if (contact) {
                let mes: Message = new Message(contact, message, false);
                contact.addMessage(mes);
                setTimeout(() => { this.systemBus.emit(contact, 'pushMessage/contact/text') }, 2000); // let things settle
            } else {
                console.error('PushService: processMessge: could not find contact');
            }
        } else {
            console.error('Push: processMessage: No contactid');
        }
    }

    private processMobileMessage(msg: any) {
        console.log('Mobile Message', msg);
    }

    public installPwa(): void {
        this.promptEvent.prompt();
    }

    public async getMessages() {
        if (!navigator.serviceWorker) {
            console.error('No Serviceworker defined in navigator');
            return [];
        }
        return new Promise((resolve, _reject) => {
            // Create a Message Channel
            let msg_chan = new MessageChannel();
            console.log('MessageChannel', msg_chan);
            // Handler for recieving message reply from service worker
            msg_chan.port1.onmessage = (event) => {
                resolve(event.data);
            };

            // Send message to service worker along with port for reply
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage('ansible', [msg_chan.port2]);
            } else {
                console.error('Service worker not supported');
            }
        });
    }

    async onBusMessage(_message: any, type: string) {
        if (type === 'contacts/gotContact') {
            this.gotContacts = true;
            this.checkMessages();
        } else if (type === 'iot/connection/connected') {
            // console.log('PushService: Endpoint', this.endpoint);
            if (this.endpoint) {
                this.checkMessages();
                let sub = await this.getSubscription();
                //  let sub = await this.swPush.subscription.toPromise();
                if (sub) {
                    if (sub.endpoint !== this.endpoint) {
                        console.log('Updateing PushSubscription');
                        this.endpoint = sub.endpoint;
                        let ps = new PushRegistration(this.deviceID);
                        ps.subscription = sub;
                        console.warn('sub=' + ps);
                        this.api.setPushSubscription(ps).catch(err => console.error('Could not subscribe to notifications', err));
                        this.subscrided = true;
                    } else {
                        console.log('PushSubscription unchanged');
                    }
                } else {
                    console.log('PushService: subscription is null');
                }
            }
        } else if (type === 'user/loggedOut') {
            this.unsubscribe();
        } else if (type === 'user/loggedIn') {
            this.deviceID = this.appConfig.getItem('deviceID');
            if (!this.deviceID) {
                this.deviceID = this.uuid();
                this.appConfig.setItem('deviceID', this.deviceID);
            }
            if (navigator.serviceWorker) {
                this.pollForUpdates();
            }
        }
    }

    private async getSubscription() {
        let sub: PushSubscription;
        if (navigator.serviceWorker) {
            let registrations = await navigator.serviceWorker.getRegistrations();
            console.log('Registrations', registrations);
            sub = await registrations[0].pushManager.getSubscription();
        } else {
            console.error('In Cordova?');
        }
        console.log('Subscription'.sub);
        return sub;
    }

    private checkMessages() {
        this.getMessages().then((messages: any) => {
            console.log('PushService: Got messages', messages);
            messages.forEach((element: any) => {
                this.processMessage(element);
            });
        });
    }

    busMessageFilter(messageType: string): boolean {
        return (messageType === 'contacts/gotContacts' ||
            messageType === 'iot/connection/connected' ||
            messageType === 'user/loggedOut' ||
            messageType === 'user/loggedIn');
    }

    public unsubscribe() {
        console.log('PushService: unsubscribe');
        if (this.subscrided === true) {
            this.api.pushUnsubscribe(this.deviceID).catch(err => console.error('Could unubscribe from notifications', err));
            this.swPush.unsubscribe();
            this.subscrided = false;
        }
    }

    private uuid() {
        let i, random;
        let result = '';

        for (i = 0; i < 32; i++) {
            random = Math.random() * 16 | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random))
                .toString(16);
        }
        return result;
    };
}
