// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: true,
    region: 'us-west-2',
    identityPoolId: 'us-west-2:ea280c53-366f-42e1-8b2d-8042786afe34',
    userPoolId: 'us-west-2_GGrLqSOD2',
    appId: 'e6h91gned1r5ta2fk4jfgj365',
    vapidKey: 'BAbn5r6FwqGsIfwl2cXDoVG6kknqwD9uMyg8MAJkd2gQoMpUZ4ctV3L6DQwlSDNETXRq0Hldh37T61mDPJ8qtgY',
    iotEndpoint: 'a1ge3olrc3kfl8-ats.iot.us-west-2.amazonaws.com',
    apiInvokeUrl: 'https://api.dev.eqcall.com',
    stripeKey: 'pk_test_8dKMDI8V9zhCc63EL31pqxB200jPRKvIGv',
    redirectUriSI: 'https://127.0.0.1:4200/cognito',
    redirectUriSO: 'https://127.0.0.1:4200/cognito',
    oauthDomain: 'oauth.dev.eqcall.com'
};
