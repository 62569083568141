<div *ngIf="!restricted">
  <div class="private-chat-container">
    <div class="row private-chat-row">
      <div class="col-12">
        <span class="private-chat-span">
          <div class="private-chat-header">
            <div class="d-flex">
              <div class="mr-auto p-2">
                <button type="button" (click)="close()" class="btn-rtc btn-outline-light btn-lg pc-exit"
                  [title]="tr.get('contact','close')"><span class="d-flex align-items-center btn-outline-light">
                    <span class="p-2 d-none d-sm-block">{{group.nickname}}</span><i
                      class="p-2 fa fa-long-arrow-left"></i> </span>
                </button>
              </div>
              <div class="p-2 d-none d-sm-block">
                <span>
                  <button type="button" class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                    (click)="connectRtc(false)" data-placement="top" [title]="tr.get('contact','p.call')">
                    <i class="fa fa-phone"></i>
                  </button>
                  <button type="button" class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                    (click)="connectRtc(true)" data-placement="top" [title]="tr.get('contact','v.call')">
                    <i class="fa fa-video-camera"></i>
                  </button>
                </span>
              </div>
              <div *ngIf="!weAreAnon" class="p-2">
                <div class="dropdown mr-2">
                  <button class="btn-rtc btn-outline-light btn-lg pc-right-menu" type="button" id="dropdownChat"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-ellipsis-h"></i>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownChat">
                    <span class="full-width">
                      <button *ngIf="group.state[0]==='connected'" class="dropdown-item" type="button"
                        (click)="connectRtc(false)" id="call">{{tr.get("contact","p.call")}}</button>
                      <button *ngIf="group.state[0]==='connected'" class="dropdown-item" type="button"
                        (click)="connectRtc(true)" id="vcall">{{tr.get("contact","v.call")}}</button>
                    </span>
                    <div class="dropdown-divider"></div>
                    <span class="full-width">
                      <button (click)="addRemove()" class="dropdown-item" type="button" data-toggle="modal"
                        data-target="#editgModal" id="edit">{{tr.get("contact","editg")}}</button>
                      <button class="dropdown-item" type="button" data-toggle="modal" data-target="#leavegModal"
                      id="leaveg">{{tr.get("contact","leaveg")}}</button>
                      <button class="dropdown-item" type="button" data-toggle="modal" data-target="#deletegModal"
                      id="deleteg">{{tr.get("contact","deleteg")}}</button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
        <br />
      </div>
    </div>
    <app-chat-window [messenger]="group"></app-chat-window>
  </div>
</div>
<!-- Delete Modal -->
<div class="modal fade" id="deletegModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{tr.get("contact","manageg")}}{{group.nickname}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="No">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{tr.get("contact","deletegq")}} {{tr.get("contact","deleteyg")}}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-warning" data-dismiss="modal" data-placement="top"
          [title]="tr.get('contact','deleten')">{{tr.get("button","no")}}</button>
        <button (click)="removeGroup()" type="button" class="btn btn-primary" data-dismiss="modal" data-placement="top"
          [title]="tr.get('contact','deletey')">{{tr.get("button","yes")}}</button>
      </div>
    </div>
  </div>
</div>
<!-- Leave Group Modal -->
<div class="modal fade" id="leavegModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{tr.get("contact","manageg")}}{{group.nickname}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="No">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{tr.get("contact","leavegq")}} {{tr.get("contact","leavegy")}}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-warning" data-dismiss="modal" data-placement="top"
          [title]="tr.get('contact','leavegn')">{{tr.get("button","no")}}</button>
        <button (click)="removeGroup()" type="button" class="btn btn-primary" data-dismiss="modal" data-placement="top"
          [title]="tr.get('contact','leavegy')">{{tr.get("button","yes")}}</button>
      </div>
    </div>
  </div>
</div>
