<button (click)="togglePersonal()" type="button" class="collapsed btn-outline-contact btn-lg btn-block"
  data-toggle="collapse" data-target="#collapsePersonal" aria-expanded="false" data-placement="top"
  [title]="tr.get('account-profile','personalt')"
  [ngClass]="{'btn-primary active':showpersonal,'btn-default':!showpersonal}" style="white-space: normal;">
  Personal
</button>
<div id="collapsePersonal" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-body ta-left">
        <div>
          <form role="form" action="">
            <p class="mt-1">{{tr.get("account-profile","display-name")}}</p>
            <input class="form-control" type="text" [(ngModel)]="contact.nickname" name="nickname"
              [placeholder]="tr.get('account-profile','display-name')" style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","email-address")}}</p>
            <input class="form-control" type="email" [(ngModel)]="contact.email" name="email"
              [placeholder]="tr.get('account-profile','email-addressp')" style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","first-name")}}</p>
            <input class="form-control" type="text" [(ngModel)]="contact.mailingAddress.firstName" name="firstName"
              [placeholder]="tr.get('account-profile','first-namep')" autocomplete="given-name"
              style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","last-name")}}</p>
            <input class="form-control" type="text" [(ngModel)]="contact.mailingAddress.lastName" name="lastName"
              [placeholder]="tr.get('account-profile','last-namep')" autocomplete="family-name"
              style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","street")}}</p>
            <input class="form-control" type="text" [(ngModel)]="contact.mailingAddress.street" name="street"
              [placeholder]="tr.get('account-profile','streetp')" autocomplete="street-address"
              style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","city")}}</p>
            <input class="form-control" type="text" [(ngModel)]="contact.mailingAddress.city" name="city"
              autocomplete="address-level2" [placeholder]="tr.get('account-profile','cityp')"
              style="margin-top: -15px;">
            <app-countryregion [curentCountry]="contact.mailingAddress.country"  [currentRegion]="contact.mailingAddress.region"
             (countryChange)="countryChange($event)" (regionChange)="regionChange($event)"></app-countryregion>
            <p class="mt-1">{{tr.get("account-profile","postal-code")}}</p>
            <input class="form-control" type="text" [(ngModel)]="contact.mailingAddress.postalCode" name="postalCode"
              [placeholder]="tr.get('account-profile','postal-codep')" autocomplete="postal-code"
              style="margin-top: -15px;">
            <input [value]="&nbsp;&nbsp;tr.get('button','save')&nbsp;&nbsp;" [title]="tr.get('button','savet')"
              type="button" class="collapsed btn-lg btn-info btn-block mt-2"
              (click)="submitPersonal();togglePersonal()">
          </form>
          <hr>
          <form>
            <p>{{tr.get("account-profile","caller-id")}} </p>
            <div class="row">
              <div class="col-sm" style="text-align: center;">
                <input type="text" class="form-control" [placeholder]="tr.get('account-profile','caller-idp')"
                  appTelInput (telNumberOutput)="setNumber($event)" (intlTelInputObject)="setIntelTel($event)">
              </div>
              <div class="col-sm">
                <input value="Validate" type="button" [disabled]="!callerID" class="btn-lg btn-info btn-block"
                  (click)="validate()" style="height: 46px">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<button (click)="toggleBusiness()" class="collapsed btn-outline-contact btn-lg btn-block"
  data-target="#collapseBusiness" data-toggle="collapse" aria-expanded="false" data-placement="top"
  [title]="tr.get('account-profile','businesst')"
  [ngClass]="{'btn-primary active':showbusiness,'btn-default':!showbusiness}"
  style="margin-top: 4px; white-space: normal;">
  Business
</button>
<div id="collapseBusiness" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-body ta-left">
        <div>
          <form role="form" action="">
            <p class="mt-1">{{tr.get("account-profile","business-name")}}</p>
            <input class="form-control" type="text" value="eqCall"
              [placeholder]="tr.get('account-profile','business-namep')" style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","business-address")}}</p>
            <input class="form-control" type="text" value=""
              [placeholder]="tr.get('account-profile','business-addressp')" style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","business-tax")}}</p>
            <input class="form-control" type="text" value="" [placeholder]="tr.get('account-profile','business-taxp')"
              style="margin-top: -15px;">
            <input type="button" [value]="&nbsp;&nbsp;tr.get('button','save')&nbsp;&nbsp;"
              [title]="tr.get('button','savet')" class="collapsed btn-lg btn-info btn-block mt-4"
              (click)="submitBusiness();toggleBusiness()" data-toggle="collapse" data-target="#collapseBusiness" />
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<button (click)="toggleAcc()" class="collapsed btn-outline-contact btn-lg btn-block" data-toggle="collapse"
  data-target="#collapseAccount" aria-expanded="false" data-placement="top" title="Toggle Account Settings"
  [ngClass]="{'btn-primary active':showacc,'btn-default':!showacc}" style="margin-top: 4px; white-space: normal;">
  {{tr.get("account-profile","accountt")}}
</button>
<div id="collapseAccount" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-body ta-left">
        <div>
          <form role="form" action="">
            <p class="mt-1 ta-center">System Notifications</p>
            <hr>
            <div class="row">
              <div class="col-auto">
                <input id="id_auto_recharge1" type="checkbox" value="" class="checkbox-template">
              </div>
              <div class="col">
                {{tr.get("account-profile","account-emailsl")}}
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <input id="id_auto_recharge3" type="checkbox" value="" class="checkbox-template">
              </div>
              <div class="col">
                {{tr.get("account-profile","account-notificationsl")}}
              </div>
            </div>
            <hr>
            <input type="button" [value]="&nbsp;&nbsp;tr.get('button','save')&nbsp;&nbsp;"
              [title]="tr.get('button','savet')" class="collapsed btn-lg btn-info btn-block mt-2"
              (click)="submitAccount();toggleAcc()" data-toggle="collapse" data-target="#collapseAccount" />
            <hr>
            <a href="" data-toggle="collapse" data-parent="#accordion" data-target="#collapseDeleteAccount"
              aria-expanded="false" data-placement="top">Close and Remove My Account</a>
            <div id="collapseDeleteAccount" class="panel-collapse collapse" aria-expanded="false">
              <button type="button" class="collapsed btn btn-danger btn-lg btn-block mt-2" data-toggle="modal"
                data-target="#deleteAccountModal" id="logout" data-placement="top"
                [title]="tr.get('account-profile','account-closet')">{{tr.get("account-profile","account-closeb")}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<button (click)="toggleSecurity()" class="collapsed btn-outline-contact btn-lg btn-block" data-toggle="collapse"
  data-target="#collapsePassword" aria-expanded="false" data-placement="top"
  [title]="tr.get('account-profile','security-npasswordp')"
  [ngClass]="{'btn-primary active':showsecurity,'btn-default':!showsecurity}"
  style="margin-top: 4px; white-space: normal;">
  {{tr.get("account-profile","security")}}
</button>
<div id="collapsePassword" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-body ta-left">
        <div>
          <form role="form" action="">
            <p class="mt-1">{{tr.get("account-profile","security-cpassword")}}</p>
            <input #currentPass autocomplete="current-password"
              [placeholder]="tr.get('account-profile','security-cpasswordp')" class="form-control" type="password"
              required style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","security-npassword")}}</p>
            <input #newPass autocomplete="new-password" class="form-control" type="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" [title]="tr.get('account-profile','security-npasswordt')"
              [placeholder]="tr.get('account-profile','security-npasswordp')" required style="margin-top: -15px;">
            <p class="mt-1">{{tr.get("account-profile","security-epassword")}}</p>
            <input #confirmPass autocomplete="new-password" class="form-control" type="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              [placeholder]="tr.get('account-profile','security-epasswordp')" required style="margin-top: -15px;">
            <input type="button" [value]="&nbsp;&nbsp;tr.get('button','save')&nbsp;&nbsp;"
              [title]="tr.get('button','savet')" class="collapsed btn-lg btn-info btn-block mt-4"
              (click)="submitSecurity(currentPass.value, newPass.value, confirmPass.value)" data-toggle="collapse"
              data-target="#collapsePassword" />
          </form>
        </div>
      </div>
    </div>
  </div>
</div>