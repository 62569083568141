
import {RtcChatMessage, RtcService, RtcConnection} from '../../../service/rtc.service';
import {Component, OnInit} from '@angular/core';
import { TranslationService } from '../../../service/translation.service';

@Component({
  selector: 'app-globalchat',
  templateUrl: './globalchat.component.html',
  styleUrls: ['../../../../assets/css/custom.css']
})
export class GlobalchatComponent implements OnInit {

  public messages: RtcChatMessage[] = [];
  public connections: RtcConnection[] = [];
  public selectedContact: any = 'Everyone';
  constructor(private rtcSvc: RtcService, public tr: TranslationService) {
    this.connections = this.rtcSvc.rtcConnections;
  }

  ngOnInit(): void {
    this.messages = this.rtcSvc.rtcChatMessages;
  }

  public sendMessage(message: string) {
    console.log(this);
    if (this.selectedContact === 'Everyone') {
      console.log('GlobalchatComponent: sendMessage: Sending to all');
      this.rtcSvc.sendChatMessageToAll(message);
    } else {
      (<RtcConnection>this.selectedContact).sendChatMessage(message);
    }
  }
}
