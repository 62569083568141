import { Key, KeyService } from '../../../../service/key.service';
import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from '../../../../service/translation.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-key]',
    templateUrl: './key.component.html',
    styleUrls: ['../../../../../assets/css/custom.css'],
})
export class KeyComponent implements OnInit {
    static fqdn: string;
    public isDomain = false;
    @Input() key: Key;
    constructor(public keySvc: KeyService, public tr: TranslationService) { }

    ngOnInit() {
        this.isDomain = this.key.fqdn !== 'NONE';
        this.getFQDN();
    }

    public deleteKey() {
        this.keySvc.deleteKey(this.key);
    }

    public save(description: string, userName: string) {

        this.key.description = description;
        this.key.userName = userName;
        console.log(this.key);
        this.keySvc.updateKey(this.key);
    }

    private async getFQDN(): Promise<string> {
        if (KeyComponent.fqdn) {
            return;
        }
        const domains: string[] = await this.keySvc.getFQDNs();

        if (domains.length === 0) {
            let host = window.location.hostname;

            let port = window.location.port;

            if (port !== '443' && port.length > 0) {
                KeyComponent.fqdn = 'https://' + host + ':' + port + '/?key=';
            } else {
                KeyComponent.fqdn = 'https://' + host + '/?key=';
            }
        } else {
            // white lable
            KeyComponent.fqdn = 'https://' + domains[0] + '/?key=';
        }
        return KeyComponent.fqdn
    }

    public copy() {
        let str = KeyComponent.fqdn + this.key.keyCode
        console.log(str);

        const el = document.createElement('textarea');  // Create a <textarea> element
        el.value = str;                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
        el.style.position = 'absolute';
        // el.style.left = '-9999px';
        el.setAttribute('style', 'width:1px;border:0;opacity:0;');  // make it visibly invisible
        document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0)     // Store selection if found
                : false;                                    // Mark as false to know no selection existed before
        el.select();
        el.focus();                             // Select the <textarea> content
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);                  // Remove the <textarea> element
        if (selected) {                                 // If a selection existed before copying
            document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
            document.getSelection().addRange(selected);   // Restore the original selection
        }
    }
}
