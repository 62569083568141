import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PushService } from '../../../service/push.service';
import { SystemBusService, MessageObserver, InputRequest } from '../../../service/system-bus.service';
import { RtcService, RtcConnection } from '../../../service/rtc.service';
import { UserLoginService } from '../../../service/cognito.service';
import { ContactsService, Contact } from '../../../service/contacts.service';
import { TranslationService } from '../../../service/translation.service';
import { MessengerGroup } from '../../../service/message.service';
declare var PushNotification: any;

@Component({
    selector: 'app-popup-controller',
    templateUrl: './popup-controller.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})
export class PopupControllerComponent implements OnInit, MessageObserver, OnDestroy, AfterViewInit {
    private rtcConnections: RtcConnection[];
    private inCall: boolean;
    private newVersionAvailble: any;
    public remoteRequest: any = { message: 'none', connection: { remoteNickName: 'none' } };
    public iOS = ['iPad', 'iPhone', 'iPod', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(navigator.platform) >= 0;
    group: MessengerGroup;
    private videoPlayList: HTMLVideoElement[] = [];
    private BlankinputRequest = new InputRequest();
    public inputRequest = this.BlankinputRequest

    constructor(private pushSvc: PushService, private systemBus: SystemBusService,
        private rtcSvc: RtcService, public tr: TranslationService,
        private userService: UserLoginService, private contactsService: ContactsService, public translation: TranslationService) {
        this.rtcConnections = rtcSvc.rtcConnections;


        window.addEventListener('beforeunload', (event) => {
            if (this.rtcConnections.length > 0) {
                event.returnValue = 'Leaving will disconnect you';
            }
        });
    }

    ngOnInit() {
        this.systemBus.subscribe(this);
    }

    ngAfterViewInit(): void {
        this.checkPerms();
        this.checkIsAnon();
    }

    private checkPerms() {
        // tslint:disable-next-line:no-unused-expression
        if ((<any>navigator).permissions && (<any>navigator).permissions.query) {
            (<any>navigator).permissions.query({ name: 'microphone' }).
                then((permissionStatus: any) => { if (permissionStatus.state !== 'granted') { this.DoNotHaveDevicePerms() } })
                .catch((err: any) => console.warn(err));
        } else if ((window as any).cordova) {
            let cordova = (<any>window).cordova;
            cordova.plugins.diagnostic.getMicrophoneAuthorizationStatus(
                (status: any) => {
                    if (status === cordova.plugins.diagnostic.permissionStatus.GRANTED) {
                        console.log(' Cordova: Microphone use is authorized');
                    } else {
                        console.log(' Cordova: Microphone use is NOT authorized ', status);
                        cordova.plugins.diagnostic.requestRuntimePermissions( (state: any) => {
                            switch (state) {
                                case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                                    console.log('Permission granted to use the camera');
                                    break;
                                case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                                    console.log('Permission to use the camera has not been requested yet');
                                // tslint:disable-next-line: no-switch-case-fall-through
                                case cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
                                    console.log('Permission denied to use the camera - ask again?');
                                    this.DoNotHaveDevicePerms();
                                    break;
                                case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                                    console.log('Permission permanently denied to use the camera - guess we won\'t be using it then!');
                                    break;
                            }
                        }, function (error: string) {
                            console.error('The following error occurred: ' + error);
                        }, [cordova.plugins.diagnostic.permission.CAMERA, cordova.plugins.diagnostic.permission.RECORD_AUDIO]);

                    }
                }, (error: string) => {
                    console.error('Cordova, The following error occurred: ' + error);
                    this.DoNotHaveDevicePerms();
                }, false
            );
        } else {
            this.DoNotHaveDevicePerms();
        }
    }

    private DoNotHaveDevicePerms() {
        if (this.userService.getKeyCode()) {
            (<any>$('#permissionsRequest')).modal('show');
        } else {
            setTimeout(() => { (<any>$('#permissionsRequest')).modal('show'); }, 15000);
        }
    }

    private checkIsAnon() {
        if (this.userService.getKeyCode()) {
            const mesg = {
                type: 'info',
                message: this.tr.get('popup', 'anonMsg'),
                timeOut: 30,
            };
            this.systemBus.emit(mesg, 'warning');
        }
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
    }

    onBusMessage(message: any, type: string): void {
        if (type === 'contacts/newRequest') {
            let contact = <Contact>message;
            this.showNewContactRequest(contact);

        } else if (type === 'rtc/connection/closed') {
            if (this.rtcConnections.length === 1) {

                this.inCall = false;
                if (this.newVersionAvailble) {
                    this.showNewVersionModal();
                }
                if (this.isAnon()) {
                    (<any>$('#registerRequest')).modal('show');
                }
            }
        } else if (type === 'rtc/connection/new') {
            if (this.rtcConnections.length === 1) {
                this.inCall = true;
            }
        } else if (type === 'contacts/gotContacts') {
            if (!this.isAnon() && this.contactsService.contactsList.numberAll() > 0 && !this.iOS) {

                if ('Notification' in window) {
                    console.log('Notification');
                    let val = Notification.permission;
                    console.log('Notification status=' + val);
                    if (val === 'default') {
                        setTimeout(() => { (<any>$('#notificationRequest')).modal('show') }, 60000);
                    } else if (val === 'denied') {
                        console.error('User denied Notifications');
                    } else {
                        console.log('Have Notification permission');
                        this.pushSvc.subscribe();
                    }
                } else if ('PushNotification' in window) {
                    console.log('PushNotification');
                    PushNotification.hasPermission((data: any) => {
                        if (data.isEnabled) {
                            console.log('Notification isEnabled');
                            this.pushSvc.subscribe();
                        } else {
                            console.log('Mobile push permission problem ', data);
                        }
                    });
                } else {
                    console.error('Nothing?');
                }
            }
            if (!this.isAnon()) {
                setTimeout(() => {
                    if (this.pushSvc.installable) {
                        (<any>$('#installPWARequest')).modal('show');
                    }
                }, 5000);
            }
        } else if (type === 'pushService/NewVersion') {
            this.newVersionAvailble = true;
            if (!this.inCall) {
                this.showNewVersionModal();
            }
        } else if (type === 'rtc/remoteControl/request') {
            this.setRequestMessage(message);
            (<any>$('#remoteControlRequest')).modal('show');
        } else if (type === 'showGroupMembershipModal') {
            this.showGroupMembership(message);
        } else if (type === 'rtc/videoplayRequest') {
            this.newVersionAvailble = true;
            if (!this.inCall) {
                this.showNewVideoPlayModal(message);
            }
        } else if (type === 'inputRequest') {
            this.showInputRequest(message);
        }
    }

    private isAnon(): any {
        return this.userService.isAnonLogin();
    }

    busMessageFilter(messageType: string): boolean {
        if (messageType.startsWith('rtc/connection') ||
            messageType === 'contacts/gotContacts' ||
            messageType === 'pushService/NewVersion' ||
            messageType === 'rtc/remoteControl/request' ||
            messageType === 'showGroupMembershipModal' ||
            messageType === 'rtc/videoplayRequest' ||
            messageType === 'contacts/newRequest' ||
            messageType === 'inputRequest' ||
            messageType === 'rtc/chatMessage/new') {
            return true;
        } else {
            return false;
        }
    }

    public installPWA() {
        (<any>$('#installPWARequest')).modal('hide');
        this.pushSvc.installPwa();
    }

    public reload() {
        (<any>$('#newVersionRequest')).modal('hide');
        window.location.reload();
    }

    private showNewVersionModal() {
        (<any>$('#newVersionRequest')).modal('show');
    }

    public notificationSubscribe() {
        (<any>$('#notificationRequest')).modal('hide');
        this.pushSvc.subscribe();
    }

    public register() {
        this.userService.logout(false);
        let registerPage = location.origin + '/home/register'
        location.replace(registerPage);
    }

    public logout() {
        this.userService.logout(true);
    }

    private setRequestMessage(message: any) {
        this.remoteRequest = message;
    }

    public remoteRequestResponse(answer: boolean) {
        console.log('Answer ', this.remoteRequest);
        (<any>$('#remoteControlRequest')).modal('hide');
        this.rtcSvc.answerRemoteControlRequest(answer, this.remoteRequest.connection);
        this.remoteRequest = { message: 'none', connection: { remoteNickName: 'none' } };
    }

    public devicePerms() {
        (<any>$('#permissionsRequest')).modal('hide');
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).
            then((stream) => {
                stream.getTracks().forEach(track => {
                    track.stop();
                });
            }).
            catch((err) => {
                console.log('Gettting audio and video devices failed, trying just audio', err);

                navigator.mediaDevices.getUserMedia({ audio: true }).
                    then((stream) => {
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }).
                    catch((err1) => {
                        console.error(err1); (<any>$('#permissionsNotice')).modal('show');
                    });
            });
    }

    public showPermsSite() {
        (<any>$('#permissionsNotice')).modal('hide');
        window.open('https://support.google.com/chrome/answer/2693767', '_blank');
    }

    private showNewVideoPlayModal(videoElement: HTMLVideoElement) {
        this.videoPlayList.push(videoElement);
        console.log('Showing video play request');
        (<any>$('#videoStartRequest')).modal('show');
    }

    public startVideo() {
        (<any>$('#videoStartRequest')).modal('hide');
        this.videoPlayList.forEach((video: HTMLVideoElement) => {
            video.play().catch((err) => { console.error('Error playing video', err) });
        });
        this.videoPlayList.length = 0;
    }

    public showGroupMembership(group: MessengerGroup) {
        this.group = group;
        console.log('Showing group stuff');
        (<any>$('#groupAddReomveModal')).modal('show');
    }

    private showNewContactRequest(contact: Contact) {
        this.systemBus.emit('New Contact Request from ' + contact.nickname, 'toast');
    }

    public deleteAccount() {
        this.userService.deleteAccount();
    }

    private showInputRequest(inputRequest: InputRequest) {
        //  console.error('InputRequest ', inputRequest);
        if (!this.inputRequest.resolve) {
            this.inputRequest = inputRequest;
            (<any>$('#inputRequestModel')).modal('show');
            console.log('Showing ', this.inputRequest, $('#inputRequestModel'));
        } else {
            console.error('InputRequest in process');
        }
    }

    public inputRequestSubmit(value: any) {
        // console.error('InputRequest value ', value);
        (<any>$('#inputRequestModel')).modal('hide');
        const resolve = this.inputRequest.resolve;
        this.inputRequest = this.BlankinputRequest;
        resolve(value);
    }
}

