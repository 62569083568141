import { Directive, ElementRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
@Directive({
  selector: '[appFixedaspect]'
})
export class FixedaspectDirective implements AfterViewInit, OnDestroy {
  private cw = 0;
  private ch = 0;
  private actualHeight = 0;
  private vidW = 0;
  private vidH = 0;
  private count = 0;
  private nodes: Node[] = [];
  private pinnedNode: Node;
  private pinnedHodeHeight = 0;
  private pinnedNodeWidth = 0;
  private minStripRatio = 0.2;
  private minheight = 0;

  private cols = 0;
  private rows = 0;
  private offset = 0;
  private hoffset = 0;
  private resizeObserver: ResizeObserver;
  private mutationObserver: MutationObserver;
  private asp = 1.77;
  @Input()
  set aspectRatio(asp: number) {
    this.asp = asp;
    this.relayout();
  }

  get aspectRatio(): number {
    return this.asp;
  }

  @Input()
  set minStripHeightRatio(min: number) {
    this.minStripRatio = min;
    this.relayout();
  }

  get minStripHeightRatio(): number {
    return this.minStripRatio;
  }

  constructor(public elementRef: ElementRef) {
    let element: HTMLElement = <HTMLElement>this.elementRef.nativeElement;
    // Container can not user static positioning
    element.style.position = 'absolute';
    // the point is not to scrole
    element.style.scrollBehavior = 'hide';
    let rect = element.getBoundingClientRect();
    this.cw = rect.width;
    this.ch = rect.height;

    this.resizeObserver = new ResizeObserver((_entries: any) => {
      console.log('Resize event');
      this.relayout();
    });
    this.resizeObserver.observe(element);

    const config = { childList: true };
    this.mutationObserver = new MutationObserver(mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              (<Element>node).hasAttribute('fartarget')
            ) {
              this.mutationObserver.observe(node, {
                attributes: true,
                attributeFilter: ['pinned']
              });
            }
          });
          mutation.removedNodes.forEach(node => {
            console.log('Node removed ', node);
            if (node.isSameNode(this.pinnedNode)) {
              this.pinnedNode = null;
            }
          });

          console.log('A child node has been added or removed.');

          this.relayout();
        } else if (mutation.type === 'attributes') {
          let node = mutation.target;
          if ((<Element>node).hasAttribute('pinned')) {
            this.pinnedNode = node;
          } else {
            this.pinnedNode = null;
          }
          this.relayout();
          console.log(
            'The ' + mutation.attributeName + ' attribute was modified.',
            mutation
          );
        }
      }
    });

    this.mutationObserver.observe(element, config);
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
    this.mutationObserver.disconnect();
  }

  relayout() {
    console.log('Relayout');
    this.update();
    this.setChildDimentions();
  }

  public update() {
    console.log('update');
    this.setBoxDimensions();
    let boxArea = this.cw * this.ch;
    let maxVidArea = boxArea / this.count;
    console.log(
      'count:' +
      this.count +
      'cw:' +
      this.cw +
      ' ch:' +
      this.ch +
      ' boxArea:' +
      boxArea +
      ' maxVidArea:' +
      maxVidArea
    );
    let maxVidWidth = Math.floor(Math.sqrt(maxVidArea * this.aspectRatio));
    if (maxVidWidth > this.cw) {
      console.log('width too large');
      maxVidWidth = this.cw;
    }
    let maxVidHeight = Math.floor(maxVidWidth / this.aspectRatio);
    if (maxVidHeight > this.ch) {
      console.log('Height too large');
      maxVidHeight = this.ch;
      maxVidWidth = Math.floor(maxVidHeight * this.asp);
    }
    console.log('1 maxw: ' + maxVidWidth + ' maxh:' + maxVidHeight);
    // make multiple of box width by rounding up
    if (this.count > 1) {
      this.cols = Math.ceil(this.cw / maxVidWidth);
      maxVidWidth = Math.floor(this.cw / this.cols);
      maxVidHeight = Math.floor(maxVidWidth / this.aspectRatio);
      this.rows = Math.ceil(this.count / this.cols);
    } else {
      this.cols = 1;
      this.rows = 1;
    }
    this.offset = this.cw - this.cols * maxVidWidth;
    console.log(
      '2 maxw: ' +
      maxVidWidth +
      ' maxh:' +
      maxVidHeight +
      ' cols:' +
      this.cols +
      ' rows:' +
      this.rows +
      ' offset ' +
      this.offset
    );
    // adjust
    if (this.rows * maxVidHeight > this.ch) {
      console.log('Adjusting height');
      maxVidHeight = Math.floor(this.ch / this.rows);
      maxVidWidth = Math.floor(maxVidHeight * this.aspectRatio);
      this.offset = this.cw - this.cols * maxVidWidth;
      if (this.offset >= maxVidWidth) {
        console.log('offset too large ' + this.offset);
        this.cols += 1;
        // this.rows -= 1;
        maxVidWidth = Math.floor(this.cw / this.cols);
        maxVidHeight = Math.floor(maxVidWidth / this.aspectRatio);
        // is last row empty now?
        let empt = Math.ceil(this.count / this.cols) !== this.rows;
        console.log('Emply rows = ' + empt);
        if (empt) {
          this.rows -= 1;
        }
        if (maxVidHeight * this.rows > this.ch) {
          console.log(
            'MaxVidHeight larget than ch: ' +
            this.ch +
            ' current:' +
            maxVidHeight * this.rows
          );
          maxVidHeight = Math.floor(this.ch / this.rows);

          maxVidWidth = Math.floor(maxVidHeight * this.aspectRatio);
          this.cols = Math.floor(this.cw / maxVidWidth);
          let newRows = Math.ceil(this.count / this.cols);
          if (newRows !== this.rows) {
            console.log('Rows changed');
            maxVidWidth = Math.floor(this.cw / this.cols);
            maxVidHeight = Math.floor(maxVidWidth / this.aspectRatio);
            this.cols = Math.floor(this.cw / maxVidWidth);
            newRows = Math.ceil(this.count / this.cols);
          }
          this.rows = newRows;
        }
      }
      this.offset = this.cw - this.cols * maxVidWidth;
    }

    this.vidW = maxVidWidth;
    this.vidH = maxVidHeight;

    console.log(
      '3 maxw: ' +
      maxVidWidth +
      ' maxh:' +
      maxVidHeight +
      ' cols:' +
      this.cols +
      ' rows:' +
      this.rows +
      ' offset ' +
      this.offset
    );
    let totalHeight = this.pinnedHodeHeight + maxVidHeight * this.rows;
    this.hoffset = this.actualHeight - totalHeight;
    console.log('Height offset' + this.hoffset);

    if (
      this.pinnedNodeWidth > 0 &&
      this.pinnedNodeWidth < this.cw &&
      this.hoffset > 0
    ) {
      console.log('Adjust pinnedNode height');
      let byheight =
        (this.pinnedHodeHeight + this.hoffset) * this.asp < this.cw;
      if (byheight) {
        console.log('byHeight');
        this.pinnedHodeHeight = this.pinnedHodeHeight + this.hoffset;
        this.pinnedNodeWidth = this.pinnedHodeHeight * this.asp;
        this.hoffset = 0;
      } else {
        this.pinnedNodeWidth = this.cw;
        this.pinnedHodeHeight = Math.floor(this.pinnedNodeWidth / this.asp);
      }
      totalHeight = this.pinnedHodeHeight + maxVidHeight * this.rows;
      this.hoffset = this.actualHeight - totalHeight;
    }
    if (this.hoffset > 0) {
      this.hoffset = Math.ceil(this.hoffset / 2);
    }
    console.log('Height offset' + this.hoffset);
  }

  private setBoxDimensions() {
    let element = this.elementRef.nativeElement;

    this.cw = element.clientWidth;
    this.ch = element.clientHeight;
    this.actualHeight = this.ch;
    this.minheight = Math.round(this.ch * this.minStripRatio);

    console.log('set box 1: w:' + this.cw + ' h:' + this.ch);
    if (this.pinnedNode && element.children.length > 1) {
      this.pinnedNodeWidth = this.cw;
      let ph = Math.floor(this.pinnedNodeWidth / this.asp);
      console.log('ph =' + ph);
      let height = this.ch - ph;
      if (height < this.minheight) {
        console.log('set box: minimum pinned height');

        this.pinnedHodeHeight = this.ch - this.minheight;
        this.pinnedNodeWidth = this.pinnedHodeHeight * this.asp;
        this.ch = this.minheight;
      } else {
        this.pinnedHodeHeight = Math.floor(this.pinnedNodeWidth / this.asp);
        this.ch = this.ch - this.pinnedHodeHeight;
      }

      this.nodes = [];
      (<NodeList>element.childNodes).forEach(node => {
        console.log('set box: node', node);
        if (!node.isSameNode(this.pinnedNode)) {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            (<Element>node).hasAttribute('fartarget')
          ) {
            this.nodes.push(node);
          }
        }
      });
    } else {
      this.pinnedHodeHeight = 0;
      this.pinnedNodeWidth = 0;
      this.nodes = element.children;
    }
    console.log('set box 2: w:' + this.cw + ' h:' + this.ch, this.nodes);

    this.count = this.nodes.length;
  }

  private setChildDimentions() {
    console.log('count:', this.count);
    if (this.pinnedNode) {
      let node: HTMLElement = <HTMLElement>this.pinnedNode;
      node.style.position = 'absolute';
      node.style.boxSizing = 'border-box';
      node.style.top = this.hoffset + 'px';

      if (this.pinnedNodeWidth < this.cw) {
        let poff = (this.cw - this.pinnedNodeWidth) / 2;
        node.style.left = poff + 'px';
      } else {
        node.style.left = '0px';
      }
      node.style.width = this.pinnedNodeWidth.toString() + 'px';
      node.style.height = this.pinnedHodeHeight.toString() + 'px';
    } else {
      this.pinnedHodeHeight = 0;
    }
    for (let x = 1; x <= this.nodes.length; x++) {
      let pos = this.getVideoPos(x);
      // console.log(pos);
      let node: HTMLElement = <HTMLElement>this.nodes[x - 1];
      node.style.position = 'absolute';
      node.style.boxSizing = 'border-box';

      node.style.top = pos.y + this.hoffset + this.pinnedHodeHeight + 'px';
      node.style.left = pos.x + 'px';
      node.style.width = this.vidW.toString() + 'px';
      node.style.height = this.vidH.toString() + 'px';
    }
  }

  public getVideoPos(count: number) {
    let row = Math.ceil(count / this.cols);
    let col = count - (row - 1) * this.cols;
    let toff = this.offset;
    if (row === Math.ceil(this.count / this.cols)) {
      let s = Math.floor(this.count / this.cols);
      let c = this.count - s * this.cols;
      if (c > 0) {
        toff = this.cw - this.vidW * c;
      }
    }
    let vx = (col - 1) * this.vidW + toff / 2;
    let vy = (row - 1) * this.vidH;
    return { x: vx, y: vy, row: row, col: col };
  }
}
