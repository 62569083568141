import { AudioService } from '../../../service/audio.service';
import { ContactsService } from '../../../service/contacts.service';
import { KeyService, Key } from '../../../service/key.service';
import { SystemBusService, MessageObserver } from '../../../service/system-bus.service';
import { Component, OnDestroy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TwilioService } from '../../../service/twilio.service';
import { RtcService, RtcConnection } from '../../../service/rtc.service';
import { TranslationService } from '../../../service/translation.service';



@Component({
    selector: 'app-dialer',
    templateUrl: './dialer.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})


export class DialerComponent implements MessageObserver, OnDestroy, OnInit {

    @Input() dialerStyle: string;
    @Output() inCallEvent = new EventEmitter();
    public validNumber: boolean;
    private telInput: any;
    public hosted = false;
    public keys: Key[] = [];
    public key: string;
    public numberToCall = '';
    public nameToCall: string;
    public showCalling = false;
    public incall = false;
    public dialPad = true;
    public infoPad = false;
    public connectionInfo: string[] = [];
    public simple = false;
    public connections: RtcConnection[] = [];
    public inCallConnecting = false;

    constructor(public twilioSvc: TwilioService, private keySvc: KeyService,
        private contactsSvc: ContactsService, private systemBus: SystemBusService,
        private audioService: AudioService, rtcSvc: RtcService, public tr: TranslationService) {
        this.connectionInfo = twilioSvc.connectionInfo;
        this.keys = this.keySvc.keys;
        this.systemBus.subscribe(this);
        this.connections = rtcSvc.rtcConnections;
        this.initSounds();
    }

    private async initSounds() {
        let list: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'hash', 'star'];
        list.forEach(key => {
            this.audioService.preLoadURL('./assets/sounds/dtmf-' + key + '.mp3');
        });
    }


    ngOnInit(): void {
        if (this.dialerStyle === 'simple') {
            this.simple = true;
        }
    }

    onBusMessage(message: any, type: string): void {
        if (type === 'phonecall/ended') {
            this.incall = false;
            this.numberToCall = '';
            this.telInput.setNumber(this.numberToCall);
            this.showCalling = false;
        } else if (type === 'phonecall/started') {
            this.incall = true;
            this.inCallConnecting = false;
            if (this.simple) {
                this.twilioSvc.setAsAudioDevice();
            }
        } else if (type === 'phonecall/incomming') {
            this.numberToCall = message.parameters.From;
        } else if (type === 'contacts/gotlocalContact') {
            this.hosted = this.contactsSvc.localContact[0].hasPermission('HOSTEDCALLS');
        }
    }

    busMessageFilter(messageType: string): boolean {
        if (messageType.startsWith('phonecall/') ||
            messageType === 'contacts/gotlocalContact') {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
    }

    public buttonPress(button: string) {
        console.log('Button ' + button);
        if (button === 'DEL') {
            const cur = this.telInput.getNumber();
            if (!cur || cur.length < 1) {
                return;
            }
            this.numberToCall = cur.slice(0, -1);
            this.telInput.setNumber(this.numberToCall);
            const valid = this.telInput.isValidNumber();
            if (valid) {
                this.setNumber(this.telInput.getNumber());
            }
        } else {
            if (button === '*' || button === '#') {
                if (button === '*') {
                    button = 'star'
                } else {
                    button = 'hash';
                }
            } else {
                const cur = this.telInput.getNumber();
                this.numberToCall = cur + button;
                this.telInput.setNumber(this.numberToCall);
                const valid = this.telInput.isValidNumber();
                if (valid) {
                    this.setNumber(this.telInput.getNumber());
                }
            }
            this.audioService.playURL('./assets/sounds/dtmf-' + button + '.mp3');
        }
    }

    public async addToConference() {
        if (this.key) {
            if (!this.nameToCall) {
                this.nameToCall = this.numberToCall + '-' + this.key;
            }
            this.twilioSvc.transferToHostedCall(this.nameToCall, this.keySvc.getKeyByCode(this.key));
        } else {
            this.twilioSvc.setAsAudioDevice();
        }
    }

    public dialpad() {
        this.dialPad = true;
        this.infoPad = false;
        this.inCallEvent.emit(true);
    }

    public infopad() {
        this.infoPad = true;
        this.dialPad = false;
    }

    public disconnect() {
        this.inCallConnecting = false;
        this.twilioSvc.end();
        this.showCalling = false;
        this.incall = false;
        this.dialPad = true;
        this.infoPad = false;
        this.inCallEvent.emit(true);
    }

    public call() {
        this.inCallConnecting = true;
        this.infoPad = true;
        this.dialPad = false;
        this.inCallEvent.emit(false);
        let local = this.contactsSvc.localContact[0];
        if (!local.hasPermission('DIALOUT')) {
            const msg = { type: 'info', message: ' Please purchase credit and verify a phonenumber to make phone calls', timeOut: 15 };
            this.systemBus.emit(msg, 'warning');
            this.systemBus.emit('ADDONS,DIALOUT', 'showAccounts');
        } else if (!local.callerID && !local.phoneNumber) {
            const msg = { type: 'info', message: ' Please purchase credit and verify a phonenumber to make phone calls', timeOut: 15 };
            this.systemBus.emit(msg, 'warning');
            this.systemBus.emit('PROFILE,PERSONAL', 'showAccounts');
        } else {
            if (this.twilioSvc.notAuthorized) {
                const msg = { type: 'info', message: ' Please purchase credit and verify a phonenumber to make phone calls', timeOut: 15 };
                this.systemBus.emit(msg, 'warning');
                //  (<any>$('#accountTab')).tab('show');
            } else {
                if (this.numberToCall.length > 0) {
                    this.showCalling = true;
                    this.twilioSvc.call(this.numberToCall).catch(_err => { this.disconnect() });
                    this.incall = true;
                }
            }
        }
    }

    public setKey(key: string) {
        this.key = key;
    }

    public hostedCall(hosted: boolean) {
        this.hosted = hosted;
    }

    public isValidNumber(valid: boolean) {
        this.validNumber = valid;
    }

    public setNumber(pnum: string) {
        this.numberToCall = pnum;
    }

    public telInputObject(obj: any) {
        this.telInput = obj;
        // console.log('TellinputObject', obj);
    }

    public onCountryChange(cntry: any) {
        // console.log('country', cntry);
    }
}
