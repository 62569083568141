import { Injectable, OnDestroy } from '@angular/core';
import { SystemBusService, MessageObserver } from './system-bus.service';
import { RtcService, RtcConnection } from './rtc.service';
import { EqcallapiService } from './eqcallapi.service';
import { UserLoginService } from './cognito.service';
import { Contact } from './contacts.service';
import { Key } from './key.service';
import { StreamhandlerService } from './streamhandler.service';
declare const Twilio: any;

export class AutoAnswerKey {
  public exp: number;
  constructor(public nickname: string, public key: Key) {
    this.exp = Date.now() + 60000; // one minute timeout
  }
}

@Injectable()
export class TwilioService implements MessageObserver, OnDestroy {

  public notAuthorized = false;
  private retries = 1;
  private micUserStreamNode: MediaStreamAudioSourceNode;
  private micUserStream: any;
  private token: any;
  public remoteToken: any;
  private micStream: MediaStream; // used as mic to twilio
  private speakerTapStream: MediaStream;
  private remoteAudioNode: MediaStreamAudioSourceNode;
  public numberToCall: string;
  public incall: boolean;
  public connectionInfo: string[] = [];
  private callSid: string;
  private initialized = false
  private twilioLoaded = false;
  public autoConnect = false;
  public phoneNumber: string;
  private micNode: MediaStreamAudioDestinationNode;
  private autoAnswerKeys: Set<AutoAnswerKey> = new Set<AutoAnswerKey>();
  private listenersRegistered = false;

  constructor(private rtcSvc: RtcService,
    private systemBus: SystemBusService, private userSvc: UserLoginService,
    private api: EqcallapiService, private streamHandler: StreamhandlerService) {
    this.systemBus.subscribe(this);
  }

  private async getToken() {
    console.log('get Token called');
    if (this.notAuthorized) {
      console.error('Not authorized');
      return;
    }
    if (!this.remoteToken) {
      return this.api.getTwilioToken().then((result: any) => {
        this.retries = 1;
        console.log('TwilioService: getToken: result = ', result);
        this.token = (<any>result.data)['token'];
        return this.setupDevice(this.token);
      }).catch((result: any) => {
        this.retries++;
        if (result.status === 401) {
          this.notAuthorized = true;
        } else {
          const message = {
            type: 'warning',
            message: result.data,
            timeOut: 15,
          };
          this.systemBus.emit(message, 'warning');
        }
        console.warn('TwilioService: getToken: error = ', result);
        throw result;
      });
    } else {
      this.token = this.remoteToken;
      console.log('TwilioService: getToken: using remote token', this.remoteToken);
      return this.setupDevice(this.token).catch((result: any) => {
        this.retries++;
        if (result.status === 401) {
          this.notAuthorized = true;
          console.error('Twilio not authorized');
        }
        console.warn('TwilioService: getToken: error = ', result);
        throw result;
      });
    }
  }

  private setupDevice(token: string): Promise<void> {
    console.log('setupDevice called');
    return new Promise((resolve, _reject) => {
      if (!this.listenersRegistered) {
        this.listenersRegistered = true;
        console.log('TwilioService: setupDevice: init listeners');

        Twilio.Device.offline((_devicen: any) => {
          console.log('TwilioService: offline');
          this.connectionInfo.push('\nDevice offline!');
          setTimeout(() => { this.getToken() }, 1000 * this.retries);

        });

        Twilio.Device.error((error: any) => {
          console.error('TwilioService: Error ', error);
          this.incall = false;
          this.connectionInfo.push('\nError ' + error);
        });

        Twilio.Device.connect((conn: any) => {
          console.log('Dialer: connect: connection=', conn);
          this.connectionInfo.push('\nSuccessfully established call!');
          this.callSid = conn.parameters.CallSid;
          console.log('CallID = ' + this.callSid);
          this.incall = true;
          this.systemBus.emit(this, 'phonecall/started');
          if (this.autoConnect) {
            this.setAsAudioDevice();
          }
        });

        Twilio.Device.disconnect((_conn: any) => {
          console.log('TwilioService: disconnected');
          this.connectionInfo.push('\nCall ended.');
          this.incall = false;
          this.disconnected();
        });

        Twilio.Device.incoming((conn: any) => {
          if (this.streamHandler.audioContext.state !== 'running') {
            this.streamHandler.audioContext.resume();
          }
          console.log('TwilioService: incomming call');
          this.connectionInfo.push('\nIncoming connection from ' + conn.parameters.From);
          this.incall = true;
          this.enableMic().then(() => { this.init() });
          this.systemBus.emit(conn, 'phonecall/incomming');
        });

        Twilio.Device.ready((_device: any) => {
          this.connectionInfo.push('\nDevice ready');
          console.log('TwilioService: device ready!!!!');
          resolve();
        });
      }
      console.log('TwilioService: setupDevice" device=', Twilio.Device);
      const params = {
        debug: true,
      }
      Twilio.Device.setup(token, params);
    });
  }

  private async init() {
    console.log('INIT called');
    if (!this.initialized && !this.notAuthorized) {
      await this.loadTwilio();
      this.initialized = true;
      console.log('TwillioService: init ' + this.autoConnect + '  ' + this.phoneNumber);
      console.log('TwilioService: init running');
      if (!this.micNode) {
        this.micNode = <MediaStreamAudioDestinationNode>this.streamHandler.audioContext.createMediaStreamDestination();
        this.micStream = this.micNode.stream;
      }
      if (!this.speakerTapStream) {
        let sh = this.rtcSvc.streamHandler;
        await sh.getSpeakerTap().then((stream: MediaStream) => {
          this.speakerTapStream = stream;
          console.log('TwilioService: init: got speakerTap');
          return this.getToken();
        }).then(() => { this.initialized = true; console.log('TwilioService: init finished') })
          .catch((error) => { this.initialized = false; console.error(error); this.connectionInfo.push(error.data.token) });
      } else {
        console.log('retry');
        return await this.getToken().catch(err => { this.initialized = false; throw err });
      }
    }
  }

  public async call(phoneNumber: string) {
    this.connectionInfo.push('Calling ' + phoneNumber);
    if (this.streamHandler.audioContext.state !== 'running') {
      this.streamHandler.audioContext.resume();
    }
    console.log('TwilioService: call ' + phoneNumber);
    return await this.init().then(_res => {
      if (this.initialized) {
        if (!this.autoConnect) {
          this.enableMic().then(_res2 => { this.call2(phoneNumber) });
        } else {
          this.call2(phoneNumber);
        }
      } else {
        console.log('Not initilized');
        this.disconnected();
      }
    });
  }

  private call2(phoneNumber: string) {
    this.connectionInfo.length = 0;
    this.end();
    const keyCode = this.userSvc.getKeyCode();
    console.log('TwillioService: call: calling ' + phoneNumber);
    this.connectionInfo.push('Calling ' + phoneNumber);
    let params = {
      To: phoneNumber,
      keyCode: keyCode
    };

    Twilio.Device.connect(params, undefined, {
      getInputStream: () => {
        return this.micStream
      }
    });
  }

  private async enableMic() {
    console.log('TwillioService: enableMic');
    if (!this.micUserStream) {
      await this.rtcSvc.getUserMedia().then((stream: MediaStream) => {
        this.micUserStream = stream;
        if (this.micUserStreamNode) {
          this.micUserStreamNode.disconnect();
        }
        this.micUserStreamNode = this.streamHandler.audioContext.createMediaStreamSource(stream);
        this.micUserStreamNode.connect(this.micNode);
      });
    }
  }

  private enableRTCMic() {
    console.log('TwillioService: enableRTCMic');
    if (this.micUserStreamNode) {
      this.micUserStreamNode.disconnect();
    }
    this.micUserStreamNode = this.streamHandler.audioContext.createMediaStreamSource(this.speakerTapStream);
    this.micUserStreamNode.connect(this.micNode);
  }

  public async transferToHostedCall(name: string, key: Key) {
    if (this.callSid) {
      await this.init();
      let phoneNumber = 'enqueue=' + key.keyCode;

      return this.api.transferToHostedCall(this.callSid, phoneNumber).then((result: any) => {
        console.log('TwilioService: hostedCall: result = ', result);
        this.hostedCall(name, 'queue=' + key.keyCode, key);
      }).catch(function (result: any) {
        console.error('TwilioService: hostedCall: error = ', result);
      });
    }
  }

  public async hostedCall(name: string, number: string, key: Key) {
    if (this.streamHandler.audioContext.state !== 'running') {
      this.streamHandler.audioContext.resume();
    }
    const that = this;
    console.log('TwilioService: hostedCall: Calling ' + name + ' at numner ' + number + ' with key ' + key.keyCode);
    await this.init();
    return this.api.makeHostedCall(name, key.keyCode, number, this.token).then((result: any) => {
      that.autoAnswerKeys.add(new AutoAnswerKey(name, key));
      console.log('TwilioService: hostedCall: result = ', result);
    }).catch(function (result: any) {
      console.error('TwilioService: hostedCall: error = ', result);
    });
  }

  public async setAsAudioDevice() {
    if (this.initialized) {
      console.log('TwilioService: set as audio device');
      this.enableRTCMic();
      const connection = Twilio.Device.activeConnection();
      let sh = this.rtcSvc.streamHandler;
      if (connection) {
        setTimeout(() => {
          const stream = <MediaStream>connection.getRemoteStream();
          console.log('TwillioService: adding mic remote=', stream);
          let audioCtx = this.rtcSvc.audioContext;
          this.remoteAudioNode = audioCtx.createMediaStreamSource(stream);
          sh.addToMic(this.remoteAudioNode);
        }, 150);
      }
    } else {
      console.error('Not initialized');
    }
  }

  public end() {
    this.incall = false;
    if (Twilio) {
      Twilio.Device.disconnectAll();
    }
  }

  private async disconnected() {
    this.systemBus.emit(this, 'phonecall/ended');
    let sh = this.rtcSvc.streamHandler;
    if (this.micUserStream) {
      this.micUserStreamNode.disconnect();
      this.micUserStreamNode = undefined;
      sh.returnMediaStream(this.micUserStream);
      this.micUserStream = undefined;
    }

    if (this.remoteAudioNode) {
      sh.removeFromMic(this.remoteAudioNode);
      this.remoteAudioNode.disconnect();
    }
    if (this.autoConnect && this.phoneNumber) {
      this.userSvc.logout(true);
    }
  }

  public autoAnswer(contact: Contact): boolean {
    let ret = false;
    const ct = Date.now();
    const keyCode = contact.keyCode;
    const nickname = contact.nickname;
    this.autoAnswerKeys.forEach((k) => {
      if (k.exp < ct) {
        console.warn('TwilioService: autoAnswer: key expired ', k);
        this.autoAnswerKeys.delete(k);
      } else {

        if (k.key.keyCode === keyCode && k.nickname === nickname) {
          this.autoAnswerKeys.delete(k);
          ret = true;
        }
      }
    });
    console.log('TwilioService: autoAnswer:' + ret);
    return ret;
  }

  onBusMessage(message: any, type: string): void {
    if (this.autoConnect && type === 'rtc/connection/closed') {
      this.end();
    } else if (type === 'rtc/connection/new' && this.phoneNumber) {
      let connection = <RtcConnection>message;
      connection.muteRemoteVideo(true);
    } else if (type === 'contacts/gotlocalContact') {
      let contact = <Contact>message;
      if (contact.phoneNumber) {
        this.init();
      }
    }
  }

  busMessageFilter(messageType: string): boolean {
    return (messageType === 'rtc/connection/closed' ||
      messageType === 'rtc/connection/new' ||
      messageType === 'contacts/gotlocalContact');
  }

  ngOnDestroy(): void {
    this.systemBus.unSubscribe(this);
  }

  private async loadTwilio() {
    console.log('Loadtwilio called');
    return new Promise(resolve => {
      if (!this.twilioLoaded) {
        this.twilioLoaded = true;
        const scriptElement = document.createElement('script');
        scriptElement.onload = () => { console.log('Twilio js loaded'); resolve() };
        scriptElement.src = 'assets/js/customtwilio.js'
        document.body.appendChild(scriptElement);
      }
    });
  }
}
