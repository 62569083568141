import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { SystemBusService, MessageObserver } from '../../../service/system-bus.service';
import { RtcService } from '../../../service/rtc.service';
import { ConfigService } from '../../../service/config.service';

@Component({
  selector: 'app-rtccontainer',
  templateUrl: './rtccontainer.component.html',
  styleUrls: ['./rtccontainer.component.css']
})
export class RtccontainerComponent implements OnInit, OnDestroy, MessageObserver {

  public show = 'uni';
  rtcFullscreen = false;
  rtcSwitchview = false;

  @Output() fullscreenEvent = new EventEmitter();
  @Output() switchviewEvent = new EventEmitter();
  @Input() showControls: boolean;
  @Input() settingsOverlay: boolean;
  @Input() rtcButtons: boolean;

  onBusMessage(_message: any, type: string): void {
    console.log('RtcContainer: message', _message);
    if (type === 'rtc/connection/new') {
      if (this.rtcSvc.rtcConnections.length === 1) {
        this.show = 'uni';
      } else if (this.rtcSvc.rtcConnections.length > 1) {
        this.loadSetting();
      }
    } else if (type === 'rtc/connection/removed') {
      if (this.rtcSvc.rtcConnections.length < 2) {
        this.show = 'uni';
      }
    } else {
      if (this.show === 'grid') {
        this.show = 'stripe';
        this.rtcSwitchview = true;
        this.switchviewEvent.emit(this.rtcSwitchview);
      } else {
        this.show = 'grid';
        this.rtcSwitchview = false;
        this.switchviewEvent.emit(this.rtcSwitchview);
      }
      this.config.setItem('connectionView', this.show);
    }
  }

  busMessageFilter(messageType: string): boolean {
    return messageType === 'rtccontainer/switch' || messageType === 'rtc/connection/new' || messageType === 'rtc/connection/removed'
  }

  constructor(public systemBus: SystemBusService, public rtcSvc: RtcService, private config: ConfigService) {
  }

  ngOnDestroy(): void {
    this.systemBus.unSubscribe(this);
  }

  ngOnInit() {
    this.systemBus.subscribe(this)
  }

  private loadSetting() {
    let view = this.config.getItem('connectionView');
    if (view) {
      this.show = view;
    } else {
      this.show = 'grid';
    }

  }

  public fullscreenStatus($event: any) {
    this.rtcFullscreen = $event;
    this.fullscreenEvent.emit(this.rtcFullscreen);
  }

}
