<!-- Add Contacts/Group Actions Button -->
<div class="d-flex justify-content-end close-menu" style="position: absolute; right: 30px; top: 32px">
  <div class="dropdown">
    <button class="btn btn-primary btn-sm" type="button" id="dropdownChat" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      <i class="material-icons" style="margin-top: 3px;">person_add</i>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownChat">
      <button class="dropdown-item" type="button" data-toggle="modal" data-target="#addModal" id="add">Add a
        Contact</button>
      <button class="dropdown-item" type="button" data-toggle="collapse" data-target=".multi-collapse"
        aria-expanded="false" aria-controls="multiCollapse1">Group Actions</button>
    </div>
  </div>
</div>
<!-- Group Actions -->
<div class="collapse multi-collapse" id="multiCollapseMenu">
  <div class="d-flex">
    <div class="mr-auto p-2">
      <button type="button" class="btn-rtc btn-outline-light btn-lg pc-right-menu" data-placement="top"
        [title]="tr.get('contact','v.call')" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false"
        aria-controls="multiCollapse1" style="box-shadow: none; outline: none; border: none;">
        <i class="fa fa-chevron-up"></i>
      </button>
    </div>
    <div class="p-2">
      <button (click)="createGroup()" class="btn-rtc btn-outline-light btn-lg pc-right-menu" data-toggle="collapse"
        data-target=".multi-collapse" aria-expanded="false" [title]="tr.get('criticall','rating.title')"><i
          class="material-icons pt-1 d-sm-none" style="box-shadow: none; outline: none; border: none; "> group_add</i>
        <div class="d-none d-sm-block">Create Group</div>
      </button>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end" style="position: absolute; right: 33px; margin-top: -1px">
  <!-- Filter Contacts -->
  <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: transparent; box-shadow: none; outline: none; border: none;">

    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="cursor: default;">
      <a (click)="setDisplay(0)" for="opt1" class="dropdown-item">All</a>
      <a (click)="setDisplay(1)" for="opt2" class="dropdown-item">Online</a>
      <a (click)="setDisplay(2)" for="opt3" class="dropdown-item">Offline or Away</a>
      <a (click)="setDisplay(3)" for="opt4" class="dropdown-item">Blocked</a>
      <a (click)="setDisplay(4)" for="opt5" class="dropdown-item">A-Z</a>
      <a (click)="setDisplay(5)" for="opt6" class="dropdown-item">Recent</a>
      <a (click)="setDisplay(6)" for="opt7" class="dropdown-item">Groups</a>
    </div>
  </div>
</div>
<div class="heading">
  <p>Contacts | {{status}}&nbsp;({{listLength}})</p>
</div>
<div *ngIf="display !== 0 && display !== 6">
  <div *ngFor="let contact of contacts ">
    <app-messenger-list-element [messenger]="contact" [inCall]="inCall"></app-messenger-list-element>
  </div>
</div>
<div *ngIf="display === 0">
  <div *ngFor="let group of groups ">
    <app-messenger-list-element [messenger]="group" [inCall]="inCall"></app-messenger-list-element>
  </div>
  <div *ngFor="let contact of onlineContacts ">
    <app-messenger-list-element [messenger]="contact" [inCall]="inCall"></app-messenger-list-element>
  </div>
  <div *ngFor="let contact of offlineContacts ">
    <app-messenger-list-element [messenger]="contact" [inCall]="inCall"></app-messenger-list-element>
  </div>

  <div *ngFor="let contact of blockedContacts ">
    <app-messenger-list-element [messenger]="contact" [inCall]="inCall"></app-messenger-list-element>
  </div>
</div>
<div *ngIf="display === 6">
  <div *ngFor="let group of groups ">
    <app-messenger-list-element [messenger]="group" [inCall]="inCall"></app-messenger-list-element>
    <div *ngIf="listLength === 0">
      <p>No Groups created yet.</p>
    </div>
  </div>
</div>
<div *ngIf="listLength === 0">
  <p>No Contacts or Groups to display.</p>
</div>