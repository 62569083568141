import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RtcService } from '../../../../service/rtc.service';
import { ContactsService } from '../../../../service/contacts.service';
import { SystemBusService } from '../../../../service/system-bus.service';
import { MessageService, MessengerGroup } from '../../../../service/message.service';
import { TranslationService } from '../../../../service/translation.service';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class GroupComponent implements OnInit, OnDestroy {

    public cid = '';
    @Input() group: MessengerGroup;

    public weAreAnon: boolean;
    public restricted = false;

    constructor(public rtc: RtcService, private contactsSvc: ContactsService,
        private systemBus: SystemBusService, private messageService: MessageService,
        public tr: TranslationService) {
    }

    ngOnInit() {
        const addr = this.group.destAddress;
        if (addr.indexOf(':')) {
            this.cid = addr.substring(addr.indexOf(':') + 1);
        } else {
            this.cid = addr;
        }

        let ourKey = this.contactsSvc.key;
        if (!ourKey) {
            this.weAreAnon = false;
        } else {
            this.weAreAnon = true;
        }
    }

    ngOnDestroy(): void {
        this.group = null;
    }

    public connectRtc(enableVideo: boolean) {
        const joinGroup = this.rtc.rtcConnections.length > 0;
        this.group.connectRtc(enableVideo, joinGroup);
    }

    public removeGroup() {
        console.log('Delete group', this.group);
        this.messageService.deleteGroup(this.group);
    }

    public rename(newName: string) {
        this.group.nickname = newName;
    }

    public close() {
        this.systemBus.emit(null, 'messengerSelected');
    }

    public clearChat() {
        this.messageService.sendMessage('^ClEaRmEsSaGeS', this.group);
    }

    public addRemove() {
        this.systemBus.emit(this.group, 'showGroupMembershipModal');
    }
}
