<div class="flex-container">
  <div class="flex-item" *ngFor="let rtcConnection of rtcConnections">
    <div class="video-container">
      <div class="video">
        <app-rtc-connection [connection]="rtcConnection" [showControls]="showControls" [settingsOverlay]="settingsOverlay" (fullscreenEvent)="fullscreenStatus($event)" ></app-rtc-connection>
      </div>
    </div>
  </div>
</div>
<!-- Change to Strip View Modal -->
<div class="modal fade" id="switchViewModal" data-backdrop="false" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="rtcConnections.length>1" class="modal-title">Switch to Strip View</h5>
        <h5 *ngIf="rtcConnections.length<2" class="modal-title">You need two or more callers to switch views</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="No">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button *ngIf="rtcConnections.length>1" (click)="switchView()" type="button" class="btn btn-warning" data-dismiss="modal">Switch Views</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
