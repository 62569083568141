import { AudioService } from '../../service/audio.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LoggedInCallback, UserLoginService, CognitoUtil } from '../../service/cognito.service';
import { Router } from '@angular/router';
import { ContactsService, Contact } from '../../service/contacts.service';
import { Socket } from '../../service/network.service';
import { Key, KeyService } from '../../service/key.service';
import { RtcService, RtcConnection } from '../../service/rtc.service';
import { SystemBusService, MessageObserver } from '../../service/system-bus.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../../service/translation.service';
import { MessageService, Messenger } from '../../service/message.service';
import { Screenfull } from 'screenfull';
import * as screenfull from 'screenfull';
import { EqcallapiService } from '../../service/eqcallapi.service';
import { ConfigService } from '../../service/config.service';


@Component({
    selector: 'app-criticall',
    templateUrl: './criticall.html',
    styleUrls: ['../../../assets/css/custom.css']
})

export class CriticallComponent implements LoggedInCallback, MessageObserver, OnDestroy, OnInit {

    @Input() user: Contact[] = [];

    public blinkContacts = false;
    public selectedSideBar = 1;

    public rtcConnections: RtcConnection[] = [];
    public localNickName: string;
    public inCall = false;
    public noContacts = false;
    private original = document.title;
    private timeout: any;
    public selectedMessenger: Messenger = null;
    public fullScreen = false;
    public om = false;
    public showAll = false;
    public menuExtended = false;
    public accountPath = 'PROFILE';
    openChat = false;
    showMixer = false;
    layerChat = false;
    chatStatus = false;
    rtcButtons = true;
    showVolume = false;
    dialerSlide = false;
    rtcFullscreen = false;
    rtcSwitchview = false;
    camActive = false;
    dialerActive = true;
    showControls = true;
    deviceStatus = false;
    settingsOverlay = false;
    overlayCropping = false;
    clearSaved = false;
    isOnline = true;
    isAway = false;
    isDND = false;
    isOffline = false;



    constructor(public router: Router, public userService: UserLoginService,
        public cognitoUtil: CognitoUtil, public messagService: MessageService, public rtcSvc: RtcService,
        private contactsService: ContactsService, public systemBus: SystemBusService,
        private audioService: AudioService, private keySvc: KeyService,
        public spinner: NgxSpinnerService, public tr: TranslationService, private api: EqcallapiService, private config: ConfigService) {
        this.userService.onceAuthenticated(this);
        this.rtcConnections = rtcSvc.rtcConnections;
        // setTimeout(() => { if (this.selectedSideBar === 2) { this.selectedSideBar = 1 } }, 5000);
    }

    ngOnInit() {
        this.systemBus.subscribe(this);

        this.spinner.show();
        setTimeout(() => { this.spinner.hide() }, 5000);

        // stop back button from closing app
        window.history.pushState(null, '');
        window.addEventListener('popstate', (_event) => {
            window.history.pushState(null, '');
            this.buttonClicked(null, 1);
        });
    }

    ngOnDestroy() {
        this.systemBus.unSubscribe(this);
    }

    public isAnon(): boolean {
        return this.userService.isAnonLogin();
    }

    isLoggedIn(_message: string, isLoggedIn: boolean) {
        if (isLoggedIn !== true) {
            console.error('Not logged in.');
        }
        this.user = this.contactsService.localContact;
    }

    private updateState() {
        if (this.selectedSideBar === 2) { this.selectedSideBar = 1 }
        this.spinner.hide();
        switch (this.user[0].state[0]) {
            case 'DND':
                this.isDND = true;
                this.isAway = false;
                this.isOffline = false;
                this.isOnline = false;
                break;
            case 'AWAY':
                this.isDND = false;
                this.isAway = true;
                this.isOffline = false;
                this.isOnline = false;
                break;
            case 'OFFLINE':
                this.isDND = false;
                this.isAway = false;
                this.isOffline = true;
                this.isOnline = false;
                break;
            case 'disconnected':
            case 'connected':
            case 'ONLINE':
                this.isDND = false;
                this.isAway = false;
                this.isOffline = false;
                this.isOnline = true;
                break;
            default:
                console.log('Unknown state ', this.user[0]);
                return;
        }
        // check for messages
        this.api.getQueueMessages().
            then((msgs: any[]) => { if (msgs && msgs.length > 0) { this.systemBus.emit('PAYMENT,METHODS', 'showAccounts'); } });
    }

    onBusMessage(message: any, type: string): void {
        if (!this.localNickName) {
            this.localNickName = this.contactsService.localContact[0].nickname;
        }
        if (type === 'rtc/connection/request') {
            const socket: Socket = message;
            const address = socket.getDestination();
            let contact = this.contactsService.getContactByAddress(address);
            if (!contact) {
                let nickname: string;
                let keyCode: string;
                try {
                    nickname = JSON.parse(socket.getHeader()).nickname;
                    keyCode = JSON.parse(socket.getHeader()).keyCode;
                } catch (err) {
                    console.error('CriticallComp: onBusMessage: could not get nickname from socket header');
                }
                contact = this.contactsService.createContactWithSocket(socket, nickname);
                if (keyCode) {
                    contact.keyCode = keyCode;
                }
                this.systemBus.emit('Contact Request from ' + nickname, 'toast');
            }
            // if (!this.twilioSvc.autoAnswer(contact)) {
            //     console.log('Criticall: onBusMessage: address=' + address + ' nickname = ' + contact.nickname);
            //     const x = new RingerPopup(socket, contact, this.rtcSvc, this.systemBus, this, this.audioService, this.tr);
            //     if (this.rtcConnections.length === 0) {
            //         this.audioService.playText(this.tr.get('criticall', 'answer.voice.txt.1') +
            //             this.localNickName + this.tr.get('criticall', 'answer.voice.txt.2') +
            //             contact.nickname + this.tr.get('criticall', 'answer.voice.txt.3'));
            //         this.audioService.RtcRingIn(true);
            //     } else {
            //         this.audioService.ringBusy(true);
            //     }
            // } else {
            //     this.rtcSvc.connectSocketDefaultVideo(socket, true, contact.nickname);
            // }
        } else if (type === 'rtc/chatMessage/new') {
            if (this.selectedSideBar !== 0) {
                this.selectedSideBar = 0;
            }
            this.openChat = true;
            this.layerChat = true;
            this.chatStatus = true;
        } else if (type === 'rtc/connection/new') {
            if (this.rtcConnections.length === 1) {
                this.selectedSideBar = 0;
                this.selectedMessenger = null;
                this.inCall = true;
            }
        } else if (type === 'rtc/connection/closed') {
            if (this.rtcConnections.length === 1) {
                this.selectedSideBar = 2;
                this.inCall = false;
                this.openChat = false;
                this.showMixer = false;
                this.chatStatus = false;
            }
        } else if (type === 'phonecall/incomming') {
            this.audioService.playText(this.tr.get('criticall', 'answerPhone.voice.1') + this.localNickName +
                this.tr.get('criticall', 'answerPhone.voice.2'));
            let key: Key;
            if (this.keySvc.keys.length > 0) {
                key = this.keySvc.keys[0];
            }
            //  add popup const x = new PhoneRingerPopup(message, this.twilioSvc, key, this, this.audioService, this.tr);
        } else if (type === 'newChatMessage') {
            if (this.selectedSideBar !== 2) {
                this.blinkContacts = true;
                this.tabMessage('Chat Message', 600);
            }
            this.announceChat(message);
        } else if (type === 'messengerSelected') {
            if (this.selectedMessenger) {
                this.selectedMessenger.unreadMessageCount = 0; // if we close it, we assume we read it.
            }
            this.selectedMessenger = null;
            if (message) {
                this.selectedSideBar = 0;
                setTimeout(() => this.selectedMessenger = message, 500);
            } else {
                this.selectedSideBar = 2;
            }
        } else if (type === 'pushMessage/contact/text') {
            console.log('Push recieved for contact', message);
            if (message) {
                this.selectedSideBar = 0;
                this.selectedMessenger = message;
                console.log('Selected messenger = ', this.selectedMessenger);
            }
        } else if (type === 'contacts/gotContacts') {
            if (this.contactsService.contactsList.numberAll() === 0) {
                this.noContacts = true;
            }
            this.updateState();
        } else if (type === 'showAccounts') {
            this.accountPath = message;
            this.selectedSideBar = 5;
        } else if ((type === 'notice/PM' || type === 'notice/PI') && this.selectedSideBar !== 5 && !this.inCall) {
            console.log('CriticallCompnent: opening accounts due to payment notice');
            this.systemBus.emit('PAYMENT,METHODS', 'showAccounts');
        }
    }

    private announceChat(messenger: Messenger) {
        if (messenger.messengerType === 'contact' && messenger !== this.selectedMessenger) {
            const messageText = messenger.messages[messenger.messages.length - 1].message;
            let message = this.tr.get('criticall', 'chatmsg') + messenger.nickname + '.\n';
            if (messageText.length < 50) {
                message = message + messageText;
            }
            if (messenger.unreadMessageCount === 1) {
                this.contactsService.audioService.playText(message);
            } else {
                this.contactsService.audioService.playURL('./assets/sounds/tick.mp3');
            }

            this.systemBus.emit(messenger.nickname + '\n' + messageText, 'toast');
        } else {
            this.contactsService.audioService.playURL('./assets/sounds/tick.mp3');
        }
    }

    private tabMessage(newMsg: string, howManyTimes: number) {
        let that = this;
        function step() {
            // document.title = (document.title === that.original) ? newMsg : that.original;
            if (document.title === that.original) {
                document.title = '🗨 ' + newMsg + ' 💭';
            } else {
                document.title = that.original;
            }

            if (--howManyTimes > 0) {
                that.timeout = setTimeout(step, 1000);
            };
        };

        if (howManyTimes <= 0) {
            howManyTimes = 5;
        }

        this.cancelTabMessage();
        step();
    }

    private cancelTabMessage() {
        clearTimeout(this.timeout);
        document.title = this.original;
    }

    busMessageFilter(messageType: string): boolean {
        if (messageType.startsWith('rtc/connection') ||
            messageType === 'phonecall/incomming' ||
            messageType === 'newChatMessage' ||
            messageType === 'messengerSelected' ||
            messageType === 'pushMessage/contact/text' ||
            messageType === 'contacts/gotContacts' ||
            messageType === 'rtc/chatMessage/new' ||
            messageType === 'notice/PM' ||
            messageType === 'notice/PI' ||
            messageType === 'showAccounts') {
            return true;
        } else {
            return false;
        }
    }

    addContact(email: string) {
        this.contactsService.addContact(email.toLocaleLowerCase());
    }

    /**
     * Used to control main button panel
     */
    public buttonClicked(_event: any, num: number) {
        this.deviceStatus = false;
        let element = document.getElementById('links-tab');
        if (element) {
            (<any>element).classList.remove('active');
        }

        element = document.getElementById('dialpad-tab');
        if (element) {
            (<any>element).classList.remove('active');
        }

        this.selectedMessenger = null;
        if (num === this.selectedSideBar) {
            setTimeout(() => {
                let select = this.inCall ? 0 : 2; // always show contact list by default when not in call
                this.selectedSideBar = select;
                console.log('reset selection');
            }, 250);
        } else {
            this.selectedSideBar = num;
        }
        if (num === 2) {
            this.blinkContacts = false;
            this.cancelTabMessage();
            if (this.contactsService.contactsList.numberAll() === 0) {
                this.noContacts = true;

            } else {
                this.noContacts = false;
            }
        }
    }

    public setFullScreen() {
        const el = document.getElementById('videoDiv');
        if (this.fullScreen && !(<Screenfull>screenfull).enabled) {
            (<Screenfull>screenfull).toggle(el);
        } else if ((<Screenfull>screenfull).enabled) {
            (<Screenfull>screenfull).toggle(el);
        }
    }

    public openChatWindow(event: any): any {
        console.log(event);
        if (event = true) {
            this.showMixer = false;
            this.chatStatus = true;
        }
    }

    public openMixerWindow(event: any): any {
        console.log(event);
        if (event = true) {
            this.openChat = false;
            this.chatStatus = false;
        }
    }

    public buttonsStatus($event: any) {
        this.rtcButtons = $event;
    }

    public controlStatus($event: any) {
        this.showControls = $event;
    }

    public volumeStatus($event: any) {
        this.showVolume = $event;
    }

    public closeVolume() {
        this.showVolume = false;
    }

    public fullscreenStatus($event: any) {
        this.rtcFullscreen = $event;
        console.log('RTC FullScreen Switched', $event);
    }

    public switchviewStatus($event: any) {
        this.rtcSwitchview = $event;
        console.log('RTC View Switched', $event);
    }

    public activeStatus($event: any) {
        this.camActive = $event;
    }

    public inCallStatus($event: any) {
        this.dialerActive = $event;
    }

    public overlayStatus($event: any) {
        this.overlayCropping = $event;
    }

    public soverlayStatus($event: any) {
        this.settingsOverlay = $event;
    }

    public saStatus($event: any) {
        this.showAll = $event;
    }

    public deviceonStatus(_$event: any) {
        this.selectedSideBar = 4;
        this.deviceStatus = true;
    }

    public deviceoffStatus(_$event: any) {
        this.selectedSideBar = 0;
        this.deviceStatus = false;
    }

    public toggleActive() {
        this.showAll = false;
        this.camActive = false;
    }

    public showControl() {
        this.showControls = true;
        this.openChat = false;
        this.chatStatus = false;
        this.showMixer = false;
        this.showVolume = false;
    }

    public extendMenu() {
        this.menuExtended = !this.menuExtended;
    }

    public goOnline() {
        this.isOnline = true;
        this.isAway = false;
        this.isDND = false;
        this.isOffline = false;
        this.api.setOnlineState(`ONLINE`);
    }

    public goAway() {
        this.isOnline = false;
        this.isAway = true;
        this.isDND = false;
        this.isOffline = false;
        this.api.setOnlineState(`AWAY`);
    }

    public goDND() {
        this.isOnline = false;
        this.isAway = false;
        this.isDND = true;
        this.isOffline = false;
        this.api.setOnlineState(`DND`);
    }

    public goOffline() {
        this.isOnline = false;
        this.isAway = false;
        this.isDND = false;
        this.isOffline = true;
        this.api.setOnlineState(`OFFLINE`);
    }

    public dialerSlider() {
        this.dialerSlide = !this.dialerSlide;
    }

    public logout() {
        console.warn('clearnign saved locals storage ' + this.clearSaved);
        if (this.clearSaved) {
            this.config.clear();
        }
        this.userService.logout(true);
    }
}
