<div [ngStyle]="{'padding-left':showVolume ? '58px' : '0px'}">
	<button *ngIf="showMonitor" (click)="hideMonitor()" type="button" class="btn btn-primary btn-sm" data-toggle="collapse" data-target="#style-switch" id="style-switch-button" aria-expanded="true" [ngStyle]="{'left':showVolume ? '62px' : '4px'}"><i class="fa fa-chevron-left"></i></button>
	<div *ngIf="showMonitor" class="style-switch" style="position: absolute; width: 300px; height: 254px; border: 4px solid rgb(44, 62, 80); background: #273748;">
		<app-channel-controller (controlEvent)="controlStatus($event)"></app-channel-controller>
	</div>
	<div class="d-flex justify-content-start" [ngStyle]="{'padding-left':showMonitor ? '300px' : '0px'}">
		<div *ngFor="let connection of rtcConnections" style="border: 4px solid rgb(44, 62, 80);">
		    <app-channel-element [rtcConnection]="connection" [controlled]="controlled" (monitorEvent)="monitorStatus($event)"></app-channel-element>
		</div>
	</div>
</div>
