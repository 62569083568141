import { Component, AfterViewInit, Input } from '@angular/core';
import { RtcService } from '../../../service/rtc.service';
import { AudioService, RingTone } from '../../../service/audio.service';
import { TranslationService } from '../../../service/translation.service';
import { Screenfull } from 'screenfull';
import screenfull from 'screenfull';

export class Device {
    constructor(public text: string, public id: string) {
    }
}

@Component({
    selector: 'app-media-selector',
    templateUrl: './media-selector.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})
export class MediaSelectorComponent implements AfterViewInit {


    private videoStream: MediaStream;
    public videoOutputElement: HTMLVideoElement;
    public showVideo = false;
    public useCanvas = false;
    public useHQAudio = false;
    public fullScreen = false;
    public buttonText = 'Activate';
    public videoInputs: Device[] = [];
    public audioInputs: Device[] = [];
    public audioOutputs: Device[] = [];
    public resolutions = [
        { desc: 'Default', width: 0, height: 0, idx: 0 },
        { desc: 'Small 4:3', width: 320, height: 240, idx: 1 },
        { desc: 'Medium 4:3', width: 640, height: 480, idx: 2 },
        { desc: 'Small 16:9', width: 640, height: 360, idx: 3 },
        { desc: 'Medium 16:9', width: 896, height: 504, idx: 4 },
        { desc: '720p', width: 1280, height: 720, idx: 5 },
        { desc: 'HD', width: 1920, height: 1080, idx: 6 }];
    public height = 0;
    public voices: SpeechSynthesisVoice[] = [];
    public selectedVoice: SpeechSynthesisVoice;
    public ringTone: RingTone;
    public ringTones: RingTone[];
    public mtpriv = true;
    public mtgrp = true;
    public mtspeech = true;
    public showva = false;
    public showcm = false;
    public showpasm = false;
    public showrt = false;
    public showvai = false;
    public showmsn = false;
    @Input() camActive = false;

    @Input() set isShowing(showing: boolean) {
        if (!showing && this.showVideo) {
            this.toggleVideo();
            (<any>$(this.videoOutputElement.parentNode)).collapse('hide');
        }
    }

    constructor(private rtcService: RtcService, private audioSvc: AudioService, public tr: TranslationService) {
        this.ringTones = audioSvc.ringTones;
        this.ringTone = audioSvc.currentRingTone;
        this.mtpriv = !audioSvc.privateChat
        this.mtgrp = !audioSvc.groupChat
        this.mtspeech = !audioSvc.speech;
        this.voices = audioSvc.voices;
        this.selectedVoice = audioSvc.selectedVoice;
    }

    ngAfterViewInit(): void {
        this.videoOutputElement = <HTMLVideoElement>document.getElementById('mediaSelectorVideo');
        this.enumerateDevices();
    }

    public setVoice(voiceURI: string) {
        this.audioSvc.setVoice(voiceURI);
    }

    public audioInChange(id: string) {

        this.rtcService.setAudioInDevice(id);
    }

    public audioOutChange(sinkId: string) {
        this.rtcService.setAudioOutDevice(sinkId);
    }

    public async videoInChange(id: string) {
        this.rtcService.setVideoInDevice(id);
        let sh = this.rtcService.streamHandler;
        setTimeout(() => { this.height = sh.videoDeviceHeight; console.log('height=' + this.height) }, 500);
        if (this.showVideo) {
            this.toggleVideo();
            setTimeout(() => { this.toggleVideo() }, 1000);
        }
    }

    public async enumerateDevices() {
        this.videoInputs.length = 0;
        this.audioInputs.length = 0;
        this.audioOutputs.length = 0;

        navigator.mediaDevices.enumerateDevices().then((deviceInfos: Array<MediaDeviceInfo>) =>
            this.updateSelectors(deviceInfos)).catch(this.handleError);
    }

    public async updateSelectors(deviceInfos: Array<MediaDeviceInfo>) {
        console.log('DeviceSelector: Devices=' + JSON.stringify(deviceInfos));
        let streamHandler = this.rtcService.streamHandler;
        const dev = streamHandler.getDeviceIDs();
        let audioInID = dev.audioDeviceID;
        let audioOutID = dev.audioOutDeviceID;
        let videoID = dev.videoDeviceID;
        this.useCanvas = dev.canvas;
        this.useHQAudio = dev.hqAudio;
        this.height = dev.videoHeight;
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];

            if (deviceInfo.kind === 'audioinput') {
                let device = new Device(deviceInfo.label ||
                    'microphone ' + (this.audioInputs.length + 1), deviceInfo.deviceId)
                if (audioInID === deviceInfo.deviceId) {
                    console.log('got current audio In device');
                    this.audioInputs.splice(0, 0, device);
                } else {
                    this.audioInputs.push(device);
                }
            } else if (deviceInfo.kind === 'audiooutput') {

                let device = new Device(deviceInfo.label ||
                    'speaker ' + (this.audioOutputs.length + 1), deviceInfo.deviceId);
                if (audioOutID === deviceInfo.deviceId) {
                    console.log('got current audio Out device');
                    this.audioOutputs.splice(0, 0, device);
                } else {
                    this.audioOutputs.push(device);
                }
            } else if (deviceInfo.kind === 'videoinput') {
                let device = new Device(deviceInfo.label ||
                    'camera ' + (this.videoInputs.length + 1), deviceInfo.deviceId);
                if (videoID === deviceInfo.deviceId) {
                    console.log('got current video device');
                    this.videoInputs.splice(0, 0, device);
                } else {
                    this.videoInputs.push(device);
                }
            } else {
                console.log('Some other kind of source/device: ', deviceInfo);
            }
        }
    }

    private handleError(error: any) {
        console.error('navigator.getUserMedia error: ', error);
    }
    private startVideo() {
        let sh = this.rtcService.streamHandler;
        this.videoOutputElement.srcObject = sh.getRawStreamClone();
        this.videoOutputElement.muted = true;
    }

    public toggleVideo() {
        this.showVideo = !this.showVideo;
        if (this.showVideo) {
            this.buttonText = 'Save';
            let sh = this.rtcService.streamHandler;
            sh.getMediaStream().then((stream) => {
                this.videoStream = stream; this.startVideo();
                this.enumerateDevices()
            })
                .catch((error) => { this.showVideo = false; this.handleError(error) });
        } else {
            this.buttonText = 'Activate';
            if (this.videoStream) {
                let sh = this.rtcService.streamHandler;
                sh.returnMediaStream(this.videoStream);
                this.videoStream = undefined;
                const stream = this.videoOutputElement.srcObject;
                (<any>stream).getTracks().forEach((track: any) => { track.stop() });
                this.videoOutputElement.srcObject = null;
            }
        }
    }

    public videoRes(idx: number) {
        const res = this.resolutions[idx];
        this.rtcService.setVideoResolution(res.width, res.height);
        if (this.showVideo) {
            this.toggleVideo();
            setTimeout(() => { this.toggleVideo() }, 1000);
        }
    }

    public useCanvasSettings() {
        let sh = this.rtcService.streamHandler;
        sh.setUseCanvasSettings(this.useCanvas, true);
    }

    public useHQAudioSettings() {
        let sh = this.rtcService.streamHandler;
        sh.setUseHQAudioSettings(this.useHQAudio);
    }

    public setFullScreen() {
        const el = document.getElementById('videoDiv');
        if (this.fullScreen && !(<Screenfull>screenfull).enabled) {
            (<Screenfull>screenfull).toggle(el);
        } else if ((<Screenfull>screenfull).enabled) {
            (<Screenfull>screenfull).toggle(el);
        }
    }

    public changeRingTone(index: any) {
        this.ringTones.forEach((rngtone: RingTone) => { if (rngtone.index === index) { this.ringTone = rngtone } })
        this.audioSvc.setRingTone(this.ringTone);
    }

    public mutePrivate() {
        this.audioSvc.mutePrivateChat(this.mtpriv);
    }

    public muteSpeech() {
        this.audioSvc.muteSpeech(this.mtspeech);
    }

    public muteGroup() {
        this.audioSvc.muteGroupChat(this.mtgrp);
    }

    public toggleVA() {
        this.showva = !this.showva;
    }

    public toggleCM() {
        this.showcm = !this.showcm;
    }

    public togglePasM() {
        this.showpasm = !this.showpasm;
    }

    public toggleRT() {
        this.showrt = !this.showrt;
    }

    public toggleVAI() {
        this.showvai = !this.showvai;
    }

    public toggleMSN() {
        this.showmsn = !this.showmsn;
    }
}
