<!-- Begin eqCall Join -->
<div class="app">
  <header class="app-header">
    <div id="collapseLogout" class="panel-collapse" aria-expanded="true">
      <div class="d-flex align-items-center justify-content-end">
        <a href="https://eqcall.com" class="logo navbar-brand d-none d-sm-block ml-3 mt-2">
          <img src="./assets/img/logo-light.png" alt="eqCall-logo">
        </a>
        <div class="d-flex align-items-center">
          <div class="p-2 d-none d-inline mt-3">
            <app-language> </app-language>
          </div>
          <div class="p-2 d-none d-inline mb-1 mr-1">
            <a [routerLink]="['/home/login']">{{tr.get('login','login')}} </a> |
            <a [routerLink]="['/home/register']">{{tr.get('login','register')}} </a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="app-body">
    <main class="app-content">
      <div class="h-spacer d-block d-sm-none"></div>
      <div class="container-fluid mb-5 m-background">
        <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">{{tr.get('anonentry','name')}}
              </h2>
            </div>
            <div class="modal-body ta-left">
              <form class="form-signin" method="POST" action="#" role="form">
                <div class="form-group">
                  <div class="row no-gutters">
                    <div class="col-sm">
                      <input id="signupName" type="text" maxlength="50" class="form-control form-control-lg input-sm" placeholder="The name others will see you as" [(ngModel)]="cname" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm">
                        <button (click)="onConnect()" id="signupSubmit" type="submit" class="btn-send btn-warning btn-lg btn-block mt-3">{{tr.get('landinghome','connect')}}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <p style="display: none;">
                  {{tr.get('anonentry','already')}}
                  <a [routerLink]="['/home/login']">
                    <i class="fa fa-fw fa-lock"></i>{{tr.get('login','login')}}
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <footer class="app-footer-alt">
    <div class="container">
        <div class="row">
          <div class="col l4 offset-l2 s12 mt-3">
            <ul class="nav justify-content-center">
              <li><p class="white-text text-lighten-4">© 2019 eqCall.</p></li>
              <li><a href="#!" class="ml-1 footer-h">Privacy |</a>  </li>
              <li><a href="#!" class="ml-1 footer-h"> Terms |</a>  </li>
              <li><a href="#!" class="ml-1 footer-h"> GDPR</a></li>
            </ul>
          </div>
        </div>
      </div>
  </footer>
</div>