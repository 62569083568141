import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslationService } from '../../../../service/translation.service';
import { SystemBusService } from '../../../../service/system-bus.service';
import { RtcService, RtcConnection } from '../../../../service/rtc.service';

@Component({
  selector: 'app-rtcuni',
  templateUrl: './rtcuni.component.html',
  styleUrls: ['./rtcuni.component.css']
})
export class RtcuniComponent implements OnInit {
  public rtcConnections: RtcConnection[] = [];
  rtcFullscreen = false;

  @Output() fullscreenEvent = new EventEmitter();
  @Input() showControls: boolean;
  @Input() settingsOverlay: boolean;


  constructor(rtcSvc: RtcService, public systemBus: SystemBusService, public tr: TranslationService) {
    this.rtcConnections = rtcSvc.rtcConnections;
  }

  ngOnInit() {
  }

  public switchView() {
    this.systemBus.emit('grid', 'rtccontainer/switch');
  }

  public fullscreenStatus($event: any): any {
    console.log('App-rtcuni Went full screen');
    this.rtcFullscreen = $event;
    this.fullscreenEvent.emit(this.rtcFullscreen);
  }

}
