<div class="group-message-wrapper">
	<div class="chat-message">
		<div class="content">
			<strong class="chat-message-left">{{message.nickname}}</strong>
			<small class="chat-message-right">at:
                {{message.time | date:'short'}}</small>
                 <span class="chat-message-left" [id]="id">
<!-- message appended here -->
            </span>
		</div>
	</div>
</div>