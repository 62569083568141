<div class="heading"
  [ngStyle]="{'background-color':connection ?  '#212f3d' : 'auto', 'color':connection ?  'white' : 'auto'}">
  <p *ngIf="!connection">Monitoring | Control</p>
  <p *ngIf="connection">Channel: {{label}}</p>
</div>
<div class="controller">
  <div *ngIf="!connection" style="height: 138px">
    <div class="heading"
      style="margin-top: 20px; color: #37cfdc; border-top: 1px solid #212f3d; padding-left: 10px; padding-right: 10px;">
      There is no Channel Selected. Please choose a Channel to control.</div>
  </div>
  <div *ngIf="connection" class="analyser">
    <canvas id="mixerAnalyserVisualizer" style="width: 271px;">
    </canvas>
    <video id="mixerAnalyserVideo" class="canvasBacking" autoplay playsinline muted appVideocropperctl
      (cropEvent)="cropEvent($event)" [videoWidth]="videoWidth" [videoHeight]="videoHeight"
      (playing)="videoPlaying($event)">
    </video>
  </div>
  <div *ngIf="!connection" style="height: 20px">
  </div>
  <div *ngIf="connection">
    <div class="analyserButtons d-flex justify-content-center">
      <div class="button-container solo" (click)="videoClick()">{{tr.get("mixer","video")}}
        <div class="button-control" [ngStyle]="{'border-bottom':videoShowing ?  '4px solid orange' : '4px solid #666'}">
        </div>
      </div>
      <div class="button-container solo" (click)="analyserClick($event)">{{tr.get("mixer","analyser")}}
        <div class="button-control"></div>
      </div>
      <div class="button-container solo">{{tr.get("mixer","mon")}}
        <div class="button-control"></div>
      </div>
      <div class="button-container solo" (click)="cropClick()">Crop
        <div class="button-control"
          [ngStyle]="{'border-bottom':cropping?  requestAnswer? '4px solid orange':'4px solid red' : '4px solid #666'}">
        </div>
      </div>
      <div class="button-container solo" (click)="controlDevices()">Control
        <div class="button-control"
          [ngStyle]="{'border-bottom':controlled?  requestAnswer? '4px solid orange':'4px solid red' : '4px solid #666'}">
        </div>
      </div>
    </div>
  </div>
  <div
    style="position: relative; margin-top: 30px; margin-bottom: 20px; width: calc(100% + 1px); height: 4px; background: rgb(44, 62, 80);">
  </div>
  <div
    style="background: rgba(68, 104, 135, 0.45); border: 4px solid rgb(44, 62, 80); width: 300px; border-bottom: 5px solid rgb(44, 62, 80); margin-top: -23px; margin-left: -4px; height: 303px;">
    <div class="heading"
      [ngStyle]="{'background-color':connection ?  '#212f3d' : 'auto', 'color':connection ?  'white' : 'auto'}"
      style="border-top: 1px solid #212f3d">
      <p *ngIf="controlled">Devices: {{label}}</p>
    </div>
    <div *ngIf="connection && !controlled" class="heading"
      style="margin-top: 20px; color: #37cfdc; border-top: 1px solid #212f3d; padding-left: 10px; padding-right: 10px;">
      Toggle Control to view and change the devices of a Selected Guest.</div>
    <div *ngIf="!controlled && !connection" class="heading"
      style="margin-top: 20px; color: #37cfdc; border-top: 1px solid #212f3d; padding-left: 10px; padding-right: 10px;">
      Click on a Channel to control or request Guest's Devices.</div>
    <app-device-panel *ngIf="controlled && connection" [rtcConnection]="connection"
      (requestEvent)="requestStatus($event)"></app-device-panel>
  </div>
</div>