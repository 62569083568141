<div class="chat-message-wrapper"
  [ngStyle]="{'background':local ? 'rgba(52, 152, 219, 0.24)' : 'rgba(25, 101, 160)', 'float':local ? 'left' : 'right' }">
  <div class="chat-message">
    <div class="content">
      <strong class="chat-message-left">{{message.messenger.nickname}}</strong>
      <small class="chat-message-right">at:
        {{message.time | date:'short'}}</small>
      <span class="chat-message-left" [id]="id">
        <!-- message appended here -->
      </span>
    </div>
  </div>
</div>
