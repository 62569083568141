<!-- In Call Call Master Volume Overlay -->
<div *ngIf="showVolume" class="master-vol-overlay" style="margin-left: -20px;" [ngStyle]="{'transform':showMixer ? 'scale(0.8)' : 'none'}">
    <div class="fader-container" style="top: 36px;">
        <div class="channel-notches">
          <div class="channel-notch" style="display: none"></div>
          <div class="channel-notch"></div>
          <div class="channel-notch zeroed">0</div>
          <div class="channel-notch"></div>
          <div class="channel-notch"></div>
          <div class="channel-notch"></div>
          <div class="channel-notch"></div>
          <div class="channel-notch"></div>
          <div class="channel-notch"></div>
          <div class="channel-notch"></div>
        </div>
        <div class="fader-track" style="width: 5px; left: 50px;">
          <!-- -32 to 246 negative top -->
          <div id="volMasterChannelID" class="fader" style="top: 30px; width: 8px; left: -4px;" (pan)="faderPan($event)"
            (dblclick)="faderDblClick($event)">
            <div></div>
          </div>
        </div>
        <canvas [attr.id]="volLevelID" width="10px" height="318px" class="channel-meter" style="background-color: #587793; width: 5px;">
        </canvas>
    </div>
</div>  
<!-- In Call MIC Output Indicator -->
<div *ngIf="vuMeterStatus" class="vu-enabled-mic">
    <canvas id="MasterVolumeLevelID" class="vu-enabled"></canvas>
</div>







        
       


    

