<div class="modal" id="warningPopup" tabindex="-1" role="dialog" aria-labelledby="warningPopupTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document" style="z-index: 9999999">
    <div class="modal-content">
      <div class="modal-body" style="padding:10px">
        <div id="warningComponent">
        </div>
      </div>
      <div class="modal-footer" style="padding:10px">
        <button type="button" (click)="closeAll()" class="btn btn-primary"
          style="width:100% ; padding:10px">{{tr.get("button","dismiss")}}</button>
      </div>
    </div>
  </div>
</div>

<div id="toastComponent">
</div>