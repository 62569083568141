<!-- Remote Control Request Modal -->
<div class="modal" id="remoteControlRequestMsg"
    aria-labelledby="remoteControlRequestMsgTitle" style="pointer-events: none">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5  class="modal-title" id="remoteControlRequestMsgLongTitle">{{tr.get("mixer","scr")}}</h5>
                <button (click)="requestCancelled()" type="button" class="close" data-dismiss="modal" >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    {{tr.get("mixer","crm")}}
                </p>

                <textarea id="mesage" [(ngModel)]="requestMessage" name="story" rows="5" cols="33">
                </textarea>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="requestCancelled()" class="btn btn-secondary"
                    data-dismiss="modal">{{tr.get("button","cancel")}}</button>
                <button type="button" (click)="sendRequest(true)" class="btn btn-primary" data-dismiss="modal">{{tr.get("button","ok")}}</button>
            </div>
        </div>
    </div>
 </div>
<div *ngIf="requestPending" class="heading" style="margin-top: 20px; color: #37cfdc; border-top: 1px solid #212f3d">Request Pending</div>
<div *ngIf="!requestPending && !requestUnsent" class="flex-d" style="padding-top: 16px; margin-left: 10px; margin-right: 10px; transform: scale(0.95);">
    <div class="row no-gutters"  style="margin-bottom: -10px;">
        <div class="col-auto">
            <button type="button" class="btn-send btn-outline-primary btn-sm" data-placement="top"
                style="border: 1px solid #37cfdc; border-right: none; height: 42px; width: 30px; pointer-events: none">
                <i class="fa fa-video-camera" style="color: #b6baba"></i>
            </button>
        </div>
        <div class="col">
            <select (change)="videoInChange($event.target.value)" class="form-control input-sm"
                aria-describedby="camera-addon" style="height: 42px; background: rgba(20, 31, 40, 0.52);">
                <option *ngFor="let vin of videoInputs" [value]="vin.id">
                    {{vin.text}}</option>
            </select>
        </div>
    </div>
    <div class="row no-gutters" style="margin-bottom: -10px;">
         <div class="col-auto">
            <button type="button" class="btn-send btn-outline-primary btn-sm" data-placement="top"
                [title]="tr.get('media-selector','audio.so.title')" style="border: 1px solid #37cfdc; border-right: none; height: 42px; width: 30px; pointer-events: none">
                <i class="fa fa-desktop" style="color: #b6baba"></i>
            </button>
        </div>
        <div class="col">
            <select (change)="videoRes($event.target.value)" class="form-control input-sm"
                aria-describedby="camera-addon" style="height: 42px; background: rgba(20, 31, 40, 0.52);">
                <option *ngFor="let res of resolutions" [value]="res.idx" [selected]="res.height==height">{{res.desc}}
                </option>
            </select>
        </div>
    </div>
    <div class="row no-gutters" style="margin-bottom: -10px;">
        <div class="col-auto">
            <button type="button" class="btn-send btn-outline-primary btn-sm" data-placement="top"
                [title]="tr.get('media-selector','audio.ms.title')" style="border: 1px solid #37cfdc; border-right: none; height: 42px; pointer-events: none">
                <i class="fa fa-microphone" style="color: #b6baba; width: 13px"></i>
            </button>
        </div>
        <div class="col">
            <select (change)="audioInChange($event.target.value) " class="form-control input-sm"
                aria-describedby="ain-addon"  style="height: 42px; background: rgba(20, 31, 40, 0.52);">
                <option *ngFor="let ain of audioInputs" [value]="ain.id">
                    {{ain.text}}</option>
            </select>
        </div>
    </div>
    <div class="row no-gutters" style="margin-bottom: -10px;">
        <div class="col-auto">
            <button type="button" class="btn-send btn-outline-primary btn-sm" data-placement="top"
                [title]="tr.get('media-selector','audio.so.title')" style="border: 1px solid #37cfdc; border-right: none; height: 42px; pointer-events: none">
                <i class="fa fa-volume-up" style="color: #b6baba"></i>
            </button>
        </div>
        <div class="col">
            <select (change)="audioOutChange($event.target.value)" class="form-control input-sm"
                aria-describedby="aout-addon"  style="height: 42px; background: rgba(20, 31, 40, 0.52);">
                <option *ngFor="let aout of audioOutputs" [value]="aout.id">
                    {{aout.text}}</option>
            </select>
        </div>
    </div>
    <div class="d-flex flex-row-reverse bd-highlight">
        <div class="p-2 bd-highlight">
            <input type="checkbox" [(ngModel)]="useHQAudio" (ngModelChange)="useHQAudioSettings($event)" class="checkbox-template" [title]="tr.get('media-selector','vd.pureHD.title')">
        </div>
        <div class="p-2 bd-highlight">
            <p style="color: #ecf0f1; font-size: 13px">{{tr.get("media-selector","vd.pureHD")}}</p>
        </div>
    </div>
</div>

        