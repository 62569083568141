<div class="body-wrap-line line-bottom-none">
  <form class="form-add-number form-echarge">
    <div class="col-lg-9">
      <div class="form-row">
        <div class="col-12">
          <div class="alert d-none" id="recharge-info" role="alert">
            <span class="icon-info"></span>
            <p class="error-text"></p>
          </div>
        </div>
      </div>
    </div>
    <br />

    <!-- 	//</form> -->
    <button style="margin-bottom: 4px; white-space: normal;" type="button"
      class="collapsed btn-outline-contact btn-lg btn-block" data-toggle="collapse" data-parent="#accordion"
      data-target="#collapseAddCards" aria-expanded="false" data-placement="top"
      [title]="tr.get('account-payment','payment-methodst')">{{tr.get("account-payment","payment-methods")}}
    </button>
    <div id="collapseAddCards" class="panel-collapse collapse" aria-expanded="false"
      style="background-color: transparent; color: #bdc3c7; margin-left: 45px; margin-right: 10px; text-align: left; padding-top: 5px; border: 0px solid #2980b9; padding-bottom: 4px; height: 0px;">
      <div class="table-responsive jcf-scrollable">
        <div class="hold-table-responsive">

          <table class="table credit-card-list table-striped card-table semibold cols-sm">
            <tbody>
              <tr *ngFor="let paymentMethod of paymentMethods">
                <td style="min-width: 170px">
                  <span class="text-sizing-14 medium">{{paymentMethod.card.brand}} </span>
                </td>
                <td style="min-width: 170px">
                  <span class="text-sizing-14 medium">XXXX XXXX XXXX {{paymentMethod.card.last4}} </span>
                </td>
                <td style="min-width: 170px">
                  <span class="text-sizing-14 medium">
                    {{paymentMethod.card.expMonth}}/{{paymentMethod.card.expYear}} </span>
                </td>
                <div *ngIf="paymentMethod.metadata.Default==='true'; else notDefault">
                  <td style="width: 171px;" class="default-card-column">
                    <span
                      class="status text-success st-default text-uppercase">{{tr.get("account-payment","default-card")}}
                    </span>
                  </td>
                </div>
                <ng-template #notDefault>
                  <td style="width: 158px;" class="make-default-card-column">
                    <button (click)="setDefaultCard(paymentMethod)" type="submit" class="text-primary"
                      style="background: transparent; border: none;">{{tr.get("account-payment","make-default")}}
                    </button>
                  </td>
                </ng-template>
                <td style="width: 146px;" class="verify-card-column"></td>
                <td style="width: 171px;">
                  <button (click)="removeCard(paymentMethod)" type="submit" class="text-primary"
                    style="background: transparent; border: none;">{{tr.get("account-payment","remove-card")}}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <div id="collapseAddNewCard" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
            <div style="all: unset">
              <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
                <div id="card-errors" role="alert" *ngIf="error" class="form-row">
                  <p style="color:orange">{{ error }}</p>
                </div>
                <div class="form-row">

                  <label for="card-info">{{tr.get("account-payment","add-cardt")}}</label>
                  <div id="card-info" #cardInfo></div>

                  <label> Name on Card</label>
                  <input id="CardHolderName" style="width:100%">
                  <app-countryregion *ngIf="showCountryRegion" [curentCountry]="contact.mailingAddress.country"
                    [currentRegion]="contact.mailingAddress.region" (countryChange)="countryChange($event)"
                    (regionChange)="regionChange($event)"></app-countryregion>
                </div>
                <div class="form-row">
                  <button type="submit" [disabled]="!ccCompleted"
                    class="btn btn-primary btn-lg btn-block">{{tr.get("account-payment","add-card")}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <button (click)="clicked()" style="margin-bottom: 4px; white-space: normal;" type="button"
            class="collapsed btn-links btn-outline-primary btn-lg pull-right" data-toggle="collapse"
            data-parent="#accordion" data-target="#collapseAddNewCard" aria-expanded="false" data-placement="top"
            [title]="tr.get('account-payment','add-cardt')">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <button style="margin-bottom: 4px; white-space: normal;" type="button"
      class="collapsed btn-outline-contact btn-lg btn-block" data-toggle="collapse" data-parent="#accordion"
      data-target="#collapseRecharge" aria-expanded="false" data-placement="top"
      [title]="tr.get('account-payment','recharget')">{{tr.get("account-payment","recharge-account")}}
    </button>
    <div id="collapseRecharge" class="panel-collapse collapse" aria-expanded="false"
      style="background-color: transparent; color: #bdc3c7; margin-left: 45px; margin-right: 10px; text-align: left; padding-top: 5px; border: 0px solid #2980b9; padding-bottom: 4px; height: 0px;">
      <div class="row">
        <div class="col">
          <h5 class="mt-2">{{tr.get("account-payment","recharge-account")}}</h5>
          <form #ccrecharge="ngForm" (ngSubmit)="recharge()" class="recharge-form">
            <div class="form-group">
              <label class="text-uppercase" id="label_id_amount">{{tr.get("account-payment","recharge-amount")}}
              </label>
              <select [(ngModel)]="selectedValue" class="form-control form-control-lg input-sm" name="amount"
                id="id_amount">
                <option value="1000">$10.00</option>
                <option value="2500">$25.00</option>
                <option value="5000">$50.00</option>
                <option value="10000">$100.00</option>
                <option value="25000">$250.00</option>
              </select>
              <label class="text-uppercase" id="label_id_card_id">{{tr.get("account-payment","select-card")}}</label>
              <select [(ngModel)]="selectedCard" class="form-control form-control-lg input-sm" name="card_id"
                id="id_card_id">
                <option *ngFor="let pm of paymentMethods" [value]="pm.id">
                  {{pm.card.brand}} {{tr.get("account-payment","last4-card")}} {{pm.card.last4}}</option>
              </select>
              <button class="btn btn-primary text-uppercase pull-right" id="recharge_now"
                [disabled]="rechargeBTNDisable" type="submit">RECHARGE</button>
            </div>
          </form>
        </div>
        <div class="col">
          <h5 class="mt-2">{{tr.get("account-payment","auto-recharge")}}</h5>

          <form #autorecharge="ngForm" (ngSubmit)="save_auto_recharge()" class="recharge-form">
            <div class="form-group">
              <label class="text-uppercase" id="label_id_amount">{{tr.get("account-payment","recharge-amount")}}</label>
              <select [(ngModel)]="autoRechargeAmount" class="form-control form-control-lg input-sm"
                id="id_recharge_amount" name="rechargeValue">
                <option value="2500">$25</option>
                <option value="5000">$50</option>
                <option value="7500">$75</option>
                <option value="10000">$100</option>
                <option value="25000">$250</option>
                <option value="50000">$500</option>
              </select>
              <label class="text-uppercase" id="label_id_level">{{tr.get("account-payment","if-balance")}}: </label>
              <select [(ngModel)]="autoRechargeLevel" class="form-control form-control-lg input-sm"
                id="id_recharge_below_amount" name="autorechargevalue">
                <option value="1000">$10</option>
                <option value="2000">$20</option>
                <option value="5000">$50</option>
                <option value="10000">$100</option>
              </select>

              <div class="pull-left">
                <input id="id_auto_recharge2" type="checkbox" value="" class="checkbox-template"
                  [checked]="autoRecharge" (change)="autoRecharge = !autoRecharge">
                <label for="id_auto_recharge2">{{tr.get("account-payment","auto-recharge-on-off")}} </label>
              </div>
              <div class="button-wrap pull-right">
                <button class="btn btn-primary text-uppercase" type="submit">{{tr.get("button","save")}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <hr>
    </div>
  </form>
</div>
<div class="modal fade" id="receiptUrlModal" role="dialog" aria-labelledby="receipt" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="z-index: 9999">
      <div class="modal-header">
        <h5 class="modal-title" id="receipt">Charge receipt</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="chargedValue>0">Your calling account has been credited {{chargedValue | currency}}</p>
        <a class="btn btn-success" [href]="receiptUrl" target="_blank">View your official receipt here </a>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="iframeModal" role="dialog" aria-labelledby="iframe" aria-hidden="true"
  data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="z-index: 9999; width: fit-content">
      <div class="modal-header">
        <h5 class="modal-title" id="iframe">Confirm</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe *ngIf="showIframe" class="embed-responsive-item" [src]="iframeUrl" frameborder="0"
          style="border:0;height:75vh" width="600"></iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="pmfailModal" role="dialog" aria-labelledby="pmfail" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="z-index: 9999; width: fit-content">
      <div class="modal-header">
        <h5 class="modal-title" id="pmfail">Payment Failed</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{errorMessage}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>