<!-- 
  INVOICE FIELDS! EXAMPLE!
  
account_country: "AU"
account_name: "EQCALL PTY LTD"
amount_due: 0
amount_paid: 0
amount_remaining: 0
application_fee_amount: null
attempt_count: 0
attempted: true
auto_advance: false
billing_reason: "subscription_cycle"
charge: null
collection_method: "charge_automatically"
created: 1608044480
currency: "usd"
custom_fields: null
customer: "cus_GEMe3B64VfY1zd"
customer_address: null
customer_email: "mjsvmax@gmail.com"
customer_name: "mike"
customer_phone: "+18192827469"
customer_shipping: null
customer_tax_exempt: "none"
customer_tax_ids: []
default_payment_method: null
default_source: null
default_tax_rates: []
deleted: null
description: null
discount: null
due_date: null
ending_balance: -693
footer: null
hosted_invoice_url: "https://invoice.stripe.com/i/acct_1Ed46UCoTlOJooW8/invst_IZofntWIWWdWVZEco9qZhAqKcCa2f6I"
id: "in_1Hyf1gCoTlOJooW8TfaR7hQq"
invoice_pdf: "https://pay.stripe.com/invoice/acct_1Ed46UCoTlOJooW8/invst_IZofntWIWWdWVZEco9qZhAqKcCa2f6I/pdf"
lines: {object: "list", data: Array(1), has_more: false, url: "/v1/invoices/in_1Hyf1gCoTlOJooW8TfaR7hQq/lines", count: null, ?}
livemode: false
metadata: {}
next_payment_attempt: null
number: "2B13A4F7-0135"
object: "invoice"
paid: true
payment_intent: null
period_end: 1608044480
period_start: 1606688972
post_payment_credit_notes_amount: 0
pre_payment_credit_notes_amount: 0
receipt_number: null
starting_balance: -454
statement_descriptor: null
status: "paid"
status_transitions: {finalized_at: 1608048578, marked_uncollectible_at: null, paid_at: 1608048578, voided_at: null}
subscription: "sub_HN0jrEp2uRIJa6"
subscription_proration_date: null
subtotal: -239
tax: null
tax_percent: null
threshold_reason: null
total: -239
total_tax_amounts: []
transfer_data: null
webhooks_delivered_at: 1608044480 -->




<table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="transparent">
  <tr>
    <td height="20"></td>
  </tr>
  <tr>
    <td>
      <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#34495e">
        <tr class="hiddenMobile">
          <td height="40"></td>
        </tr>

        <tr>
          <td>
            <table width="480" border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
              <tbody>
                <tr>
                  <td>
                    <table width="220" border="0" cellpadding="0" cellspacing="0" align="left" class="col">
                      <tbody>
                        <tr style="border-bottom: 1px solid #7f8c8d">
                          <td align="left"><br> <img src="././././assets/img/logo-light.png" alt="eqCall-logo" width="120" border="0" /></td>
                          <td style="font-size: 21px; color: #ecf0f1; letter-spacing: -1px; font-family: 'Open Sans', sans-serif; line-height: 1; vertical-align: middle; text-align: right;">
                             <span style="font-size: 12px; color: #bdc3c7; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: middle; text-align: right;"><br />
                               <a *ngIf="invoice.status !== 'draft'" [href]="invoice.hosted_invoice_url" target="_blank" style="color: #3498db; text-decoration:underline;">Download</a>
                               <a *ngIf="invoice.status === 'open'" [href]="invoice.hosted_invoice_url" target="_blank" style="color: #3498db; text-decoration:underline;">Pay</a>
                            </span>
                          </td>
                        </tr>
                        <tr class="hiddenMobile">
                          <td height="50"></td>
                        </tr>
                        <tr>
                          <td style="font-size: 12px; color: #bdc3c7; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: top; text-align: left;">
                           TO: {{invoice.customer_name}}
                           <div *ngIf="invoice.customer_address">
                            <br *ngIf="invoice.customer_address.line1"> {{invoice.customer_address.line1}}
                            <br *ngIf="invoice.customer_address.line2"> {{invoice.customer_address.line2}}
                            <br *ngIf="invoice.customer_addresspostal_code"> {{invoice.customer_address.postal_code}}
                            <br *ngIf="invoice.customer_address.state"> {{invoice.customer_address.state}}
                            <br *ngIf="invoice.customer_address.country"> {{invoice.customer_address.country}}
                            <br *ngIf="invoice.customer_address.phone"> {{invoice.customer_phone}}
                            </div>
                            <br> Customer ID: {{invoice.customer}}
                          </td>
                          <td *ngIf="invoice.status !== 'draft'" style="font-size: 21px; color: #ecf0f1; letter-spacing: -1px; font-family: 'Open Sans', sans-serif; line-height: 1; vertical-align: top; text-align: right;">
                            Invoice
                             <br>
                             <span style="font-size: 12px; color: #bdc3c7; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: top; text-align: right;">
                                <small>#</small> {{invoice.number}}<br />
                                <small *ngIf="invoice.status !== 'draft'">Issue date: {{invoice.created * 1000 | date:'dd/MM/yyyy'}}</small>
                            </span>
                          </td>
                          <td *ngIf="invoice.status === 'draft'" style="font-size: 21px; color: #ecf0f1; letter-spacing: -1px; font-family: 'Open Sans', sans-serif; line-height: 1; vertical-align: top; text-align: right;">
                            Subscription Statement
                             <br>
                             <span style="font-size: 12px; color: #bdc3c7; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: top; text-align: right;">
                                <small *ngIf="invoice.status === 'draft'">Billing Period: {{invoice.period_start  * 1000 | date:'dd/MM/yyyy'}} - {{invoice.period_end * 1000  | date:'dd/MM/yyyy'}}</small>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
<!-- /Header -->
<!-- Order Details -->
<table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="transparent">
  <tbody>
    <tr>
      <td>
        <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#34495e">
          <tbody>
            <tr>
            <tr class="visibleMobile">
              <td height="50"></td>
            </tr>
            <tr>
              <td>
                <table width="480" border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                  <tbody>
                    <tr>
                      <th style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #bdc3c7; font-weight: normal; line-height: 1; vertical-align: top; padding: 0 10px 7px 0;" align="left">
                        {{tr.get("account-billing","c.date")}}
                      </th>
                      <th style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #bdc3c7; font-weight: normal; line-height: 1; vertical-align: top; padding: 0 0 7px;" width="54%" align="left">
                        {{tr.get("account-billing","c.type")}}
                      </th>
                      <th style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #95a5a6; font-weight: normal; line-height: 1; vertical-align: top; padding: 0 0 7px; text-align: right;" align="right">
                        {{tr.get("account-billing","c.amount")}}
                      </th>
                    </tr>
                    <tr>
                      <td height="1" style="background: #bebebe;" colspan="4"></td>
                    </tr>
                    <tr>
                      <td height="10" colspan="4"></td>
                    </tr>
                    <tr *ngFor="let line of invoice.lines.data">
                      <td scope="row" style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #95a5a6;  line-height: 18px;  vertical-align: top; padding:10px 0;" class="article">
                        {{line.period.start *1000 | date:'dd/MM/yyyy'}}
                      </td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #95a5a6;  line-height: 18px;  vertical-align: top; padding:10px 0;">{{line.description}}</td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #95a5a6;  line-height: 18px;  vertical-align: top; padding:10px 0; text-align: right; text-transform: uppercase;" align="right">{{line.amount / 100 | currency}} {{line.currency}}</td>
                    </tr>
                    <tr>
                      <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                    </tr>
                    <tr>
                      <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td height="20"></td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
<!-- /Order Details -->
<!-- Total -->
<table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="transparent">
  <tbody>
    <tr>
      <td>
        <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#34495e">
          <tbody>
            <tr>
              <td>

                <!-- Table Total -->
                <table width="480" border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                  <tbody>
                    <tr>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                        Subtotal
                      </td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; white-space:nowrap;" width="80">
                        {{invoice.total / 100 | currency}}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                        {{invoice.tax}} {{invoice.tax_percent}}
                      </td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                        {{invoice.total_tax_amounts}}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                       Balance Brought Forward
                      </td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                        {{invoice.starting_balance / -100
                                | currency}}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                       Coupon/Discount
                      </td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #646a6e; line-height: 22px; vertical-align: top; text-align:right; ">
                        {{invoice.discount}}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #95a5a6; line-height: 22px; vertical-align: top; text-align:right; ">
                        <strong>Grand Total (Incl.Tax)</strong>
                      </td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #95a5a6; line-height: 22px; vertical-align: top; text-align:right; ">
                        <strong>{{invoice.amount_due /
            100 | currency}}</strong>
                      </td>
                    </tr>
                    <tr *ngIf="invoice.status !== 'draft'">
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #b0b0b0; line-height: 22px; vertical-align: top; text-align:right; "><small>Invoice Status</small></td>
                      <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #b0b0b0; line-height: 22px; vertical-align: top; text-align:right; ">
                        <small>{{invoice.status}}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- /Table Total -->

              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
<!-- /Total -->
<!-- Information -->
<table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="transparent">
  <tbody>
    <tr>
      <td>
        <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#34495e">
          <tbody>
            <tr>
            <tr class="visibleMobile">
              <td height="40"></td>
            </tr>
            <tr>
              <td>
                <table width="480" border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                  <tbody>
                    <tr>
                      <td>
                        <table *ngIf="invoice.status !== 'draft'" width="220" border="0" cellpadding="0" cellspacing="0" align="left" class="col">

                          <tbody>
                            <tr>
                              <td style="font-size: 11px; font-family: 'Open Sans', sans-serif; color: #bdc3c7; line-height: 1; vertical-align: top; ">
                                <strong>{{invoice.account_name}}</strong>
                              </td>
                            </tr>
                            
                            <tr>
                              <td style="font-size: 12px; font-family: 'Open Sans', sans-serif; color: #bdc3c7; line-height: 20px; vertical-align: top; ">
                            ABN:52 627 299 736
                            <br> Level 27, 525 Collins St
                            <br> Melbourne, VIC, 3000
                            <br> Australia
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
              </td>
            </tr>
            <tr class="visibleMobile">
              <td height="30"></td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
<!-- /Information -->
<table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="transparent">

  <tr>
    <td>
      <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#34495e" style="border-radius: 0 0 10px 10px;">
        <tr>
          <td>
            <table width="480" border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
              <tbody>
                <tr>
                  <td *ngIf="invoice.status !== 'draft'" style="font-size: 12px; color: #bdc3c7; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: top; text-align: left;">
                    Thank you for using eqCall. <br />---End Invoice---
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="spacer">
          <td height="50"></td>
        </tr>

      </table>
    </td>
  </tr>
  <tr>
    <td height="20"></td>
  </tr>
</table>
<!--
<p> Invoice Number: {{invoice.number}} </p>
<p> Invoice Number: {{invoice.customer_name}} </p>
<p> Start Date: {{invoice.period_start  * 1000 | date:'medium'}} </p>
<p> End Date: {{invoice.period_end * 1000  | date:'medium'}} </p>
<p> Status: {{invoice.status}} </p>
<p> Start Balance: {{invoice.starting_balance / -100
								| currency}} </p>
<p> ITems </p>
<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead style="background-color: #34495e; color: #bdc3c7;">
            <tr>
                <th>{{tr.get("account-billing","c.date")}}</th>
                <th>{{tr.get("account-billing","c.type")}}</th>
                <th>{{tr.get("account-billing","c.amount")}}</th>
                <th>{{tr.get("account-billing","c.minutes")}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let line of invoice.lines.data">
                <td scope="row">{{line.period.start *1000 | date:'dd/MM/yyyy'}}</td>
                <td>{{line.description}}</td>
                <td style="text-transform: uppercase;">{{line.amount / -100
								| currency}} {{line.currency}}</td>
                <td>{{line.metadata.callDuration}}</td>
            </tr>
        </tbody>
    </table>
</div>
<p> Total: {{invoice.total / -100 | currency}} </p>
<p> Ending Balance: {{invoice.ending_balance / -100 | currency}}
</p>
<a [href]="invoice.hosted_invoice_url" target="_blank">Invoice Link</a>
<hr style="height:2px;border-width:0;color:gray;background-color:gray">
<br> -->