<!-- Begin eqCall Login -->
<div class="app">
  <header class="app-header">
    <div id="collapseLogout" class="panel-collapse" aria-expanded="true">
      <div class="d-flex align-items-center justify-content-end">
        <a href="https://eqcall.com" class="logo navbar-brand d-none d-sm-block ml-3 mt-2">
          <img src="./assets/img/logo-light.png" alt="eqCall-logo">
        </a>
        <div class="d-flex align-items-center">
          <div class="p-2 d-none d-inline mt-3">
            <app-language> </app-language>
          </div>
          <div class="p-2 d-none d-inline mb-1 mr-1">
            <a [routerLink]="['/home']">{{tr.get('login','join')}} </a> |
            <a [routerLink]="['/home/register']">{{tr.get('login','register')}} </a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="app-body">
    <main class="app-content">
      <div class="h-spacer d-block d-sm-none"></div>
      <div class="container-fluid mb-5 m-background">
        <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">{{tr.get('login','login')}}
              </h2>
            </div>
            <div class="modal-body ta-left">
              <form class="signupform" method="POST" action="#" role="form">
                <div *ngIf="errorMessage" class="alert alert-danger">{{
        					errorMessage }}</div>
                <div class="form-group">
                  <label class="control-label ta-left" for="signupUserID">{{tr.get('login','userID')}}</label> <input
                    id="signupUserID" required  maxlength="100" class="form-control form-control-lg input-sm"
                    [(ngModel)]="userID" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="form-group">
                  <label class="control-label ta-left" for="signupPassword">{{tr.get('login','password')}}</label>
                  <input id="signupPassword" required type="password" maxlength="40"
                    class="form-control form-control-lg input-sm" length="40" [(ngModel)]="password"
                    [ngModelOptions]="{standalone: true}">
                </div>
                <div class="form-group">
                  <br />
                  <button (click)="onLogin()" id="signupSubmit" type="submit"
                    class="collapsed btn-lg btn-info btn-block" data-toggle="collapse" data-parent="#accordion"
                    data-target="#collapseLoading" aria-expanded="false">{{tr.get('login','login')}}</button>
                     <button (click)="googLogin()" id="googlogin" type="submit" class="collapsed btn-lg btn-info btn-block mt-3"
                    data-toggle="collapse" data-parent="#accordion" data-target="#collapseLoading"
                    aria-expanded="false">{{tr.get('login','social')}}</button>

                  <div id="collapseLoading" class="panel-collapse collapse" aria-expanded="false">
                    <div class="load-top">
                      <p>
                        <font color="white">{{tr.get('login','loggingin')}}</font>
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="form-group ta-center">
                  <p>
                    <a [routerLink]="['/home/forgotPassword']">{{tr.get('login','forgot')}} </a> | <a
                      [routerLink]="['/home/resendCode']">{{tr.get('login','resend')}}</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <footer class="app-footer-alt">
    <div class="container">
      <div class="row">
        <div class="col l4 offset-l2 s12 mt-3">
          <ul class="nav justify-content-center">
            <li>
              <p class="white-text text-lighten-4">© 2019 eqCall.</p>
            </li>
            <li><a href="#!" class="ml-1 footer-h">Privacy |</a> </li>
            <li><a href="#!" class="ml-1 footer-h"> Terms |</a> </li>
            <li><a href="#!" class="ml-1 footer-h"> GDPR</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</div>
