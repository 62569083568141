import { Contact, ContactsService } from '../../../../service/contacts.service';
import { RtcService } from '../../../../service/rtc.service';
import { SystemBusService } from '../../../../service/system-bus.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from '../../../../service/translation.service';
import { MessageService } from '../../../../service/message.service';


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class ContactComponent implements OnInit, OnDestroy {

    public cid = '';
    @Input() contact: Contact;
    @Input() inCall: boolean;
    @Input() rtcButtons: boolean;
    @Output() saEvent = new EventEmitter();
    public isAnon: boolean;
    public weAreAnon: boolean;
    public restricted = false;
    public keyCode: string;
    public name = '';
    public showAll = false;


    constructor(public rtc: RtcService, private contactsSvc: ContactsService,
        private systemBus: SystemBusService, private messageService: MessageService,
        public tr: TranslationService) {
    }

    ngOnInit() {

        const addr = this.contact.destAddress;
        if (addr.indexOf(':')) {
            this.cid = addr.substring(addr.indexOf(':') + 1);
        } else {
            this.cid = addr;
        }
        this.keyCode = this.contact.keyCode;
        let ourKey = this.contactsSvc.key;
        if (!ourKey) {
            this.weAreAnon = false;
        } else {
            this.weAreAnon = true;
            if ((this.contact.keyCode === this.contact.destAddress) && (this.contact.keyCode === this.contact.email)) {
                this.restricted = false;
            } else {
                this.restricted = ourKey.restricted;
            }
        }
        this.isAnon = this.contact.isAnon;
        if (this.isAnon && (this.keyCode)) {
            this.name = this.keyCode + ' : ' + this.contact.nickname;
            console.error('Set keycode nickname');
        } else {
            this.name = this.contact.nickname;
        }
    }

    ngOnDestroy(): void {
        this.contact = null;
    }

    public connectRtc(enableVideo: boolean) {
        const joinGroup = this.rtc.rtcConnections.length > 0;
        this.contact.connectRtc(enableVideo, joinGroup);
    }

    public requestConnectRtc(enableVideo: boolean) {
         const joinGroup = this.rtc.rtcConnections.length > 0;
        let msg = '^CalLMe' +  (enableVideo ? 'ViDiO' : 'AuDiO') + (joinGroup ? '-jOiN' : '');
      this.messageService.sendMessage(msg, this.contact);
    }

    public removeContact() {
        this.contactsSvc.removeContact(this.contact);
        this.systemBus.emit(null, 'messengerSelected');
    }

    public blockContact(block: boolean) {
        this.contact.isBlocked = block;
        this.contactsSvc.updateContact(this.contact);
    }

    public rename() {
        let name = (<any>document.getElementById('changeName' + this.cid)).value;
        this.contact.nickname = name;
        this.contactsSvc.updateContact(this.contact);
    }

    public close() {
        this.systemBus.emit(null, 'messengerSelected');
    }

    public openDevices() {
        this.showAll = true;
        this.saEvent.emit(this.showAll);
    }

    public clearChat() {
        this.messageService.sendMessage('^ClEaRmEsSaGeS', this.contact);
    }
}


