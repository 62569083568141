<div class="table-responsive" style="text-align: left;">
    <table class="table table-striped table-hover">
        <thead style="background-color: #2c3e50; color: #bdc3c7;">
            <tr>
                <th>{{tr.get("account-billing","a.date")}}</th>
                <th>{{tr.get("account-billing","a.type")}}</th>
                <th>{{tr.get("account-billing","a.to-from")}}</th>
                <th>{{tr.get("account-billing","a.call-start")}}</th>
                <th>{{tr.get("account-billing","a.call-end")}}</th>
                <th>{{tr.get("account-billing","a.duration")}}</th>
                <th>{{tr.get("account-billing","a.call-cost")}}</th>
            </tr>
        </thead>
        <tbody *ngFor="let log of logs">
            <tr *ngIf="log.Type !== 'RECHARGE'">
                <td>{{log.Time | date:'dd/MM/yyyy'}}</td>
                <td>{{log.Type}}</td>
                <td>{{log.To}}/{{log.Frm}}</td>
                <td>{{log.Time | date:'mediumTime'}}</td>
                <td>{{log.Duration*1000+log.Time*1 | date:'mediumTime'}}</td>
                <td>{{log.Duration}}</td>
                <td style="text-transform: uppercase;">{{log.Amt/100 | currency}}{{log.Cur}}</td>
            </tr>
            <tr *ngIf="log.Type === 'RECHARGE'">
                <td>{{log.Time * 1000 | date:'dd/MM/yyyy'}}</td>
                <td>{{log.Type}}</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
                <td style="text-transform: uppercase;">{{log.Amt/100 | currency}}{{log.Cur}}</td>
            </tr>
        </tbody>
    </table>
</div>
