import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { HomeLandingComponent, HomeComponent } from './public/home.component';
import { EqCallHomeComponent } from './eqcall/landing/eqcall-home.component';
import { CriticallComponent } from './eqcall/criticall/criticall.component';
import { LoginComponent } from './public/auth/login/login.component';
import { RegisterComponent } from './public/auth/register/registration.component';
import { ForgotPassword2Component, ForgotPasswordStep1Component } from './public/auth/forgot/forgotPassword.component';
import { RegistrationConfirmationComponent } from './public/auth/confirm/confirmRegistration.component';
import { ResendCodeComponent } from './public/auth/resend/resendCode.component';
import { AnonentryComponent } from './public/auth/anonentry/anonentry.component';
import { AccountComponent } from './eqcall/criticall/account/account.component';
const homeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        children: [
            { path: 'anonentry', component: AnonentryComponent },
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent },
            { path: 'resendCode', component: ResendCodeComponent },
            { path: 'forgotPassword/:email', component: ForgotPassword2Component },
            { path: 'forgotPassword', component: ForgotPasswordStep1Component },
            { path: '', component: HomeLandingComponent }
        ]
    },
];



const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
             { path: 'cognito', component: HomeLandingComponent },
            ...homeRoutes
        ]
    },
    {
        path: 'eqCallHome', component: EqCallHomeComponent,
        children: [
            { path: 'account', component: AccountComponent },
            { path: '', component: CriticallComponent },
        ]
    }
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { enableTracing: false, useHash: true });
