import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RtcConnection, RtcService } from '../../../service/rtc.service';
import { MessageObserver, SystemBusService } from '../../../service/system-bus.service';

@Component({
    selector: 'app-mixer',
    templateUrl: './mixer.component.html',
    styleUrls: ['./mixer.component.css']
})
export class MixerComponent implements OnInit, OnDestroy, MessageObserver {

    @Input() showVolume: boolean;
    public rtcConnections: RtcConnection[] = [];
    // public selectedConnection: RtcConnection = null;
    public showMonitor = true;
    public controlled = false;

    constructor(public rtcSvc: RtcService, public systemBus: SystemBusService) {
        this.rtcConnections = rtcSvc.rtcConnections;
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
        this.rtcConnections = [];
    }

    ngOnInit() {
        this.systemBus.subscribe(this);
    }

    onBusMessage(_message: any, _type: string): void {
    }

    busMessageFilter(messageType: string): boolean {
        return messageType.startsWith('rtc/');
    }

    public monitorStatus($event: any) {
        this.showMonitor = $event;
    }

    public controlStatus($event: any) {
        this.controlled = $event;
    }

    public hideMonitor() {
        this.showMonitor = false;
    }
}
